import React from 'react'
import Productdescription from './Productdescription'
import KeyFeatures from './KeyFeatures'
import AdvanceConnectivity from './AdvanceConnectivity'
import ProductSpecification from './ProductSpecification'
import { Link } from 'react-router-dom'
import Display from './Display'

function Productsheet() {
  return (
    <>
      <div>
        <div className="product-hero-section flex items-center justify-center">
          <div className="max-w-[500px] text-center">
            <h1 className="text-[#FF5E14] text-[54px] text-center font-semibold leading-[65px]">
              C20 Lite
              ECR Terminal
            </h1>
            <p className="text-[#222222] text-[40px] text-center leading-10 mt-2	">
              A new choice to elevate your user experience
            </p>
            <Link className="bg-[#FF5E14] text-[20px] rounded-[8px] py-[15px] px-[20px] text-[#ffffff] text-center mx-auto ">
              <button className="mt-[50px]">
                C20 Lite DATASHEET
              </button>
            </Link>
          </div>
        </div></div>
      {/* <Productdescription /> */}
      <KeyFeatures />
      <Display />
      <AdvanceConnectivity />
      <ProductSpecification />
    </>
  )
}

export default Productsheet