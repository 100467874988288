import React from "react";
import Banner from "../../Component/M20Se/Banner";
import KeyFeatures from "../../Component/M20Se/KeyFeatures";
import EveryPayment from "../../Component/M20Se/EveryPayment";
import Larger from "../../Component/M20Se/Larger";
import VersatilePorts from "../../Component/M20Se/VersatilePorts";
import Specification from "../../Component/M20Se/Specification";
// import FooterNew from "../../Component/FooterNew";

const M20Se = () => {
  return (
    <div>
      <div>
        <Banner />
      </div>
      <div>
        <KeyFeatures />
      </div>

      <div>
        <EveryPayment />
      </div>
      <div>
        <Larger />
      </div>

      <VersatilePorts />

      <div>
        <Specification />
      </div>

      {/* <FooterNew /> */}
    </div>
  );
};

export default M20Se;
