import React from "react";
// import img1 from "../../assets/andpos/image1.png";
// import img2 from "../../assets/andpos/image2.png";
// import img3 from "../../assets/andpos/image3.png";

import img1 from "../../assets/andpos/posconsultancy.jpg";
import img2 from "../../assets/andpos/comprehensivesetup.jpg";
import img3 from "../../assets/andpos/trainingsupport.jpg";

const ProductSection = ({productRef}) => {
  return (
    <section  ref={productRef}
      id="product" className="bg-[#F3755B] space-y-4 md:space-y-8 lg:space-y-16 p-2 sm:p-4 md:p-6 p-6  ">
      <div className="container mx-auto lg:px-10  w-full h-full bg-[#F3755B] lg:py-16 lg:my-5">
        <div className="w-full sm:w-[40%] md:w-[60%] mx-auto text-white space-y-3 md:space-y-5 lg:space-y-5 lg:mb-8">
        <h1 className="xl:text-5xl lg:text-5xl md:xl:text-5xl sm:xl:text-2xl text-3xl font-semibold text-center">
            Product Services
          </h1>
          <p className="xl:text-base lg:text-base md:text-base text-xs text-center lg:text-[16px] leading-5 ">
            {/* Point of Sale (POS) systems are versatile and can offer a variety of
            services depending on the business type and industry. Here are some
            common types of POS services: */}
          </p>
        </div>
        <div className="w-full flex flex-col md:flex-row justify-center items-center xl:gap-10 lg:gap-10 md:gap-10 gap-6" >
          <div className="text-white flex flex-col justify-center items-center gap-4 wow fadeInLeft xl:mt-0 lg:mt-0 md:mt-[10px] mt-4">
            <img
              src={img1}
              alt="image1"
              className="w-40 h-40 md:w-52 md:h-52 lg:w-80 lg:h-80 rounded-full"
            />
            <h2 className="text-xl text-center font-semibold mt-6">POS Consultancy</h2>
            {/* <p className="xl:text-sm lg:text-sm md:text-sm text-xs justify-content "> */}
            <p className="text-center xl:text-sm lg:text-sm md:text-sm text-xs ">
              We help distributors, retailers and restaurant operators accelerate their business transformation. The retail and restaurant industries are changing widely and are accelerating towards the new payment methods and devices.
            </p>
          </div>
          <div className="text-white flex flex-col justify-center wow fadeInLeft items-center gap-4">
            <img
              src={img2}
              alt="image2"
              className="w-40 h-40 md:w-52 md:h-52 lg:w-80 lg:h-80 rounded-full "
            />
            <h2 className="text-xl font-semibold mt-6"> Comprehensive Installation and Setup</h2>
            <p className="text-center xl:text-sm lg:text-sm md:text-sm text-xs">
              Our team ensures a hassle-free installation process, configuring each POS model—AndPos ECR, Soundbox, Printers, POS machines, Scanners—to suit the business requirements. We focus on optimizing your workspace with minimal disruption.
            </p>
          </div>
          <div className="text-white flex flex-col justify-center wow fadeInLeft items-center gap-4">
            <img
              src={img3}
              alt="image3"
              className="w-40 h-40 md:w-52 md:h-52 lg:w-80 lg:h-80 rounded-full "
            />
            <h2 className="text-xl font-semibold mt-6">Training and Support</h2>
            <p className="text-center xl:text-sm lg:text-sm md:text-sm text-xs">
              Our hands-on training equips staff with the knowledge to operate the POS systems effortlessly. In addition, our dedicated support team is available to address any issues promptly, ensuring uninterrupted operations.
            </p>
          </div>
        </div>
      </div>{" "}
    </section>
  );
};

export default ProductSection;
