import React from 'react'
import Versatile from './Versatile'
import Clitefeature from './Clitefeature'

function Slidersection() {
  return (
    <div>Slidersection
        <Versatile/>
        <Clitefeature/>
    </div>
  )
}

export default Slidersection