import React from 'react'

function Displayoptions() {
  return (
    <div className='display-option xl:py-[63px] lg:py-[63px] md:py-[30px] py-[20px]'>
    <div className='container mx-auto'>
        <div className='flex items-center'>
            <div className='xl:w-[50%] lg:w-[50%] md:w-[50%] w-[100%]'>
                <div className='mb-[70px]'>
                    <div className='max-w-[510px]'>
                        <p className='xl:text-[42px] lg:text-[42px] md:text-[30px] text-[30px] font-semibold text-[#FF5E14]'>Diversift your viewing experience
                            with multiple display options</p>
                    </div>
                </div>
                <div className='border-[#F3755B] border-2 rounded-[8px] py-[13px] px-[20px] mb-[20px] max-w-[509px] w-[100%]'>
                    <label className='text-[16px] font-bold'>
                        None
                    </label>
                    <p className='font-normal text-[16px] mt-1'>
                        (single Screen)
                    </p>
                </div>
                <div className='border-[#F3755B] border-2 rounded-[8px] py-[13px] px-[20px] mb-[20px]  max-w-[509px] w-[100%]'>
                    <label className='text-[16px] font-bold'>
                        Standard CFD
                    </label>
                    <p className='font-normal text-[16px] mt-1'>
                        (10.1 “ HD 1280X800 Display Only)
                    </p>
                </div>
                <div className='border-[#F3755B] border-2 rounded-[8px] py-[13px] px-[20px] mb-[20px]  max-w-[509px] w-[100%]'>
                    <label className='text-[16px] font-bold'>
                        Premium CFD
                    </label>
                    <p className='font-normal text-[16px] mt-1'>
                        (10.1 “ HD 1280X800 Touch Panel, QR  Camera NFC)
                    </p>
                </div>
                <div className='border-[#F3755B] border-2 rounded-[8px] py-[13px] px-[20px] mb-[20px]  max-w-[509px] w-[100%]'>
                    <label className='text-[16px] font-bold'>
                        P30-T
                    </label>
                    <p className='font-normal text-[16px] mt-1'>
                        (PCI Payment Tablate)
                    </p>
                </div>
            </div>
            <div className='xl:w-[50%] lg:w-[50%] md:w-[50%] w-[100%]'>
                <img className='mx-auto' src="../../../../../assets/Images/C20 Lite 2.png">
                </img>
            </div>
        </div>
    </div>
</div>
  )
}

export default Displayoptions