import React from "react";

const KeyFeatures = () => {
  return (
    <div className="py-16">
      <div className="flex flex-wrap items-center px-8">
        <div className="w-full lg:w-1/2">
          <img src="/banner/AndposC20Se/keyfeatures.webp" alt="Key Features" />
        </div>

        <div className="w-full lg:w-1/2 pt-20 lg:pt-0">
          <div>
            <h4 className="text-5xl lg:text-3xl font-bold">Key Features:</h4>
          </div>

          <div className="pt-3 pl-4">
            <ul className="list-disc">
              <li className="lg:text-sm text-xl py-4 md:py-0">
                Powered by Android 13
              </li>
              <li className="lg:text-sm text-xl py-4 md:py-0">
                Quad-core CPU, 2.0GHz
              </li>
              <li className="lg:text-sm text-xl py-4 md:py-0">
              Main display 15.6" FHD     
              </li>
              <li className="lg:text-sm text-xl py-4 md:py-0">
                Customer display <br/>
                1: NONE (Single Screen)<br/>
                2: Standard CFD (10.1"HD 1280x800 display only)<br/>
                3: Premium CFD (10.1"HD 1280x800,touch,QR camera,NFC)<br/>
                4: P30-T PCI payment tablet
              </li>
            </ul>
          </div>

          <div className="mt-4">
            <a
              href="/devices-pdf/c20 se.pdf"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-[#1398F1] py-2 px-12 text-white rounded-full text-2xl lg:text-sm font-bold"
            >
              Download brochure
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KeyFeatures;
