import React from "react";
import Banner from "../../Component/AndposC20Pro/Banner";
import KeyFeatures from "../../Component/AndposC20Pro/KeyFeatures";
import EveryPayment from "../../Component/AndposC20Pro/EveryPayment";
import Larger from "../../Component/AndposC20Pro/Larger";
import VersatilePorts from "../../Component/AndposC20Pro/VersatilePorts";
import Specification from "../../Component/AndposC20Pro/Specification";
// import FooterNew from "../../Component/FooterNew";

const AndposC20Pro = () => {
  return (
    <div>
      <div>
        <Banner />
      </div>
      <div>
        <KeyFeatures />
      </div>

      <div>
        <EveryPayment />
      </div>
      <div>
        <Larger />
      </div>

      <VersatilePorts />

      <div>
        <Specification />
      </div>

      {/* <FooterNew /> */}
    </div>
  );
};

export default AndposC20Pro;
