import React from "react";

const AboutUs = () => {
  return (
    <>
      <div
        id="about"
        className="container mx-auto lg:px-10 w-full h-full lg:pb-16 lg:my-5"
      >
        <section className="w-4/5 mx-auto font-[inter] ">
          <h3 className="xl:text-[42px] lg:text-[42px] md:text-[42px] text-[30px] md:text-[48px] font-semibold block text-center">
            About Us
          </h3>
          {/* <p className="text-center text-xs md:text-lg mt-4 font-thin text-gray-500 ">
            Point of Sale (POS) systems are versatile and can offer a variety of
            services depending on the business type and industry.
            <br /> Here are some common types of POS services:
          </p> */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-10 pt-10 md:py-20">
            <div className="px-5">
              <h4 className="text-[20px] md:text-[25px] font-semibold">
                Make Your Payments Easy With Versatile POS Terminals
              </h4>
              <p className="pt-4 text-xs md:text-base">
                Andpos is your ultimate point-of-sale (POS) solution designed to
                transform how businesses operate. With its adaptability across
                diverse industries, Andpos ensures seamless payment processing
                and efficient operations, catering to the unique needs of every
                sector. Provides payment devices like ECR, POS machine,
                Soundbox, Biometric scanners, Printers, etc. Whether you're in
                banks, retail, hospitality, healthcare, fuel stations or any
                other domain. Andpos empowers business with cutting-edge POS
                machines tailored to streamline workflows and enhance customer
                experiences.
              </p>
            </div>
            <div className="flex justify-center items-center">
              <img
                src="../../../assets/Images/AboutUs.png"
                alt="About Us"
                className="w-full h-auto max-w-xs md:max-w-sm"
              />
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default AboutUs;
