import React from "react";

const Banner = () => {
  return (
    <div>
      <div className="relative hidden md:block">
        <div>
          <img src="/banner/AndposC20Se/C20SE.webp" alt="" />
        </div>

        <div className="absolute inset-0 flex items-center justify-center">
          {/* <div>
            <div>
              <h2 className="text-black text-4xl font-bold text-center pt-2">
                C20 SE
              </h2>
            </div>

            <div>
              <h2 className="text-black text-4xl font-bold text-center pt-2">
                ECR Terminal
              </h2>
            </div>

            <div>
              <p className="max-w-52 text-center font-light">
                A new choice to elevate your user experience
              </p>
            </div>

            <div className="flex justify-center mt-4">
              <a
                href="/devices-pdf/c20 se.pdf"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-[#1398F1] p-1 text-white rounded-full text-xs font-bold"
              >
                Download brochure
              </a>
            </div>
          </div> */}

          <div className="flex justify-between w-full px-32">
            <div></div>
            <div>
              <h2 className="text-black text-4xl font-bold text-center pt-2">
                C20 SE
              </h2>
              <h2 className="text-black text-4xl font-bold text-center pt-2">
                ECR Terminal
              </h2>

              <div>
                <p className="max-w-52 text-center font-light">
                  A new choice to elevate your user experience
                </p>
              </div>

              <div className="flex justify-center mt-4">
                <a
                  href="/devices-pdf/c20 se.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bg-[#1398F1] p-3 text-white rounded-full text-xs font-bold"
                >
                  Download brochure
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="block md:hidden">
        <div className="bg-[#E9EDF0] p-8">
          <div className="flex justify-center">
            <img src="/banner/banner_mobile.png" alt="" />
          </div>

          <div className="py-16">
            <h4 className="text-center text-[2.5rem] font-bold">C20 Lite</h4>
            <h4 className="text-center text-[2.5rem] font-bold">
              ECR Terminal
            </h4>

            <p className="pt-3 text-xl text-center">
              A new choice to elevate your <br /> user experience
            </p>

            <div className="flex justify-center mt-12">
              <a
                href="/devices-pdf/c20 se.pdf"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-[#1398F1] py-2 px-12 text-white rounded-full text-2xl font-bold"
              >
                Download brochure
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
