import React from "react";
import { FaMicrochip } from "react-icons/fa6";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { BiSolidMemoryCard } from "react-icons/bi";
import { BsSoundwave } from "react-icons/bs";

const Specification = () => {
  return (
    <div className="px-8 lg:px-40 py-12">
      <div>
        <h4 className="text-3xl font-bold">Specifications</h4>
      </div>

      <div>
        <div className="flex flex-wrap w-full pt-12">
          <div className="w-full lg:w-1/4">
            <div className="m-1 bg-[#EAEDF0] min-h-44 p-3 gap-4 rounded-lg">
              <div className="flex gap-2 items-center">
                <FaMicrochip size={24} />
                <span className="font-bold">Processor</span>
              </div>
              <div>
                <span className="text-xs">Hexa-core, up to 4.4Ghz (Intel i3 - 1215U)</span>
              </div>
            </div>
          </div>
          <div className="w-full lg:w-1/4">
            <div className="m-1 bg-[#EAEDF0] min-h-44 p-3 gap-4 rounded-lg">
              <div className="flex gap-2 items-center">
                <AiOutlineLoading3Quarters size={24} />
                <span className="font-bold">OS</span>
              </div>
              <div>
                <span className="text-xs">Windows 11 IoT</span>
              </div>
            </div>
          </div>
          <div className="w-full lg:w-1/4">
            <div className="m-1 bg-[#EAEDF0] min-h-44 p-3 gap-4 rounded-lg">
              <div className="flex gap-2 items-center">
                <BiSolidMemoryCard size={24} />
                <span className="font-bold">Memory</span>
              </div>
              <div>
                <span className="text-xs">
                Internal：
                8GB+256GB
                </span>
                {/* <div>
                  <span className="text-xs">MicroSD Card (up to 2TB)</span>
                </div> */}
              </div>
            </div>
          </div>
          <div className="w-full lg:w-1/4">
            <div className="m-1 bg-[#EAEDF0] min-h-44 p-3 gap-4 rounded-lg">
              <div className="flex gap-2 items-center">
                <BsSoundwave size={24} />
                <span className="font-bold">Accessories</span>
              </div>
              <div>
                <span className="text-xs">Screen edge expansion (Micro
                  USB)</span>
              </div>
              
            </div>
          </div>
          <div className="w-full lg:w-1/4">
            <div className="m-1 bg-[#EAEDF0] min-h-44 p-3 gap-4 rounded-lg">
              <div className="flex gap-2 items-center">
                <FaMicrochip size={24} />
                <span className="font-bold">Main Display</span>
              </div>
              <div>
                <span className="text-xs">
                15.6'', IPS, FHD, 1920x1080 px
capacitive multi-touch
                </span>
              </div>
            </div>
          </div>

          <div className="w-full lg:w-1/4">
            <div className="m-1 bg-[#EAEDF0] min-h-44 p-3 gap-4 rounded-lg">
              <div className="flex gap-2 items-center">
                <FaMicrochip size={24} />
                <span className="font-bold">Printer</span>
              </div>
              <div>
                <span className="text-xs">
                80mm Thermal Printer,
                Diameter : 80mm,
                Print speed : 200mm/s，Auto Cutter
                </span>
              </div>
            </div>
          </div>

          <div className="w-full lg:w-1/4">
            <div className="m-1 bg-[#EAEDF0] min-h-44 p-3 gap-4 rounded-lg">
              <div className="flex gap-2 items-center">
                <FaMicrochip size={24} />
                <span className="font-bold">keypad</span>
              </div>
              <div>
                <span className="text-xs">
                1 Power key with indicator light
                </span>
              </div>
            </div>
          </div>

          

          <div className="w-full lg:w-1/4">
            <div className="m-1 bg-[#EAEDF0] min-h-44 p-3 gap-4 rounded-lg">
              <div className="flex gap-2 items-center">
                <FaMicrochip size={24} />
                <span className="font-bold">Audio</span>
              </div>
              <div>
                <span className="text-xs">
                Speaker: 5W, 100dB@1m, Dual Speaker
                Headset: 3.5mm audio jack
                </span>
              </div>
            </div>
          </div>

          <div className="w-full lg:w-1/4">
            <div className="m-1 bg-[#EAEDF0] min-h-44 p-3 gap-4 rounded-lg">
              <div className="flex gap-2 items-center">
                <FaMicrochip size={24} />
                <span className="font-bold">Connectivity</span>
              </div>
              <div>
                <span className="text-xs">
                WAN:NONE
                Wi-Fi : IEEE802.11 a/b/g/n/ac 2.4GHz and 5GHz
                ETH : 1000M
                Bluetooth: Bluetooth 5.0，support BLE
                GPS: NONE
                </span>
              </div>
            </div>
          </div>

          <div className="w-full lg:w-1/4">
            <div className="m-1 bg-[#EAEDF0] min-h-44 p-3 gap-4 rounded-lg">
              <div className="flex gap-2 items-center">
                <FaMicrochip size={24} />
                <span className="font-bold">Ports</span>
              </div>
              <div>
                <span className="text-xs">
                1*USB Type-C
4*USB Type-A
1*RJ22 SerialPort
1*RJ45 Ethernet Port
1*RJ11 Cash Drawer Port
1*3.5mm Audio jack
1*Power Port
                </span>
              </div>
            </div>
          </div>

          <div className="w-full lg:w-1/4">
            <div className="m-1 bg-[#EAEDF0] min-h-44 p-3 gap-4 rounded-lg">
              <div className="flex gap-2 items-center">
                <FaMicrochip size={24} />
                <span className="font-bold">PSU
                </span>
              </div>
              <div>
                <span className="text-xs">
                19V/5A
                </span>
              </div>
            </div>
          </div>

          <div className="w-full lg:w-1/4">
            <div className="m-1 bg-[#EAEDF0] min-h-44 p-3 gap-4 rounded-lg">
              <div className="flex gap-2 items-center">
                <FaMicrochip size={24} />
                <span className="font-bold">Customer display</span>
              </div>
              <div>
                <span className="text-xs">
                Optional 1 : NONE
                Optional 2 : 10.1'', HD,
                1280x800 px
                </span>
              </div>
            </div>
          </div>

          <div className="w-full lg:w-1/4">
            <div className="m-1 bg-[#EAEDF0] min-h-44 p-3 gap-4 rounded-lg">
              <div className="flex gap-2 items-center">
                <FaMicrochip size={24} />
                <span className="font-bold">Environment</span>
              </div>
              <div>
                <span className="text-xs">
                Operating Temperature:
                0℃ to +40℃
                Storage temperature:
                -20℃ to +60℃
                Operating humidity:
                10% to 90% non-condensing
                </span>
              </div>
            </div>
          </div>

          <div className="w-full lg:w-1/4">
            <div className="m-1 bg-[#EAEDF0] min-h-44 p-3 gap-4 rounded-lg">
              <div className="flex gap-2 items-center">
                <FaMicrochip size={24} />
                <span className="font-bold">Protection</span>
              </div>
              <div>
                <span className="text-xs">
                Waterproof/Dustproof/Drop:
                IP41, 1.2m drop
                </span>
              </div>
            </div>
          </div>

          <div className="w-full lg:w-1/4">
            <div className="m-1 bg-[#EAEDF0] min-h-44 p-3 gap-4 rounded-lg">
              <div className="flex gap-2 items-center">
                <FaMicrochip size={24} />
                <span className="font-bold">Size</span>
              </div>
              <div>
                <span className="text-xs">
                235mm * 360mm * 360mm
                </span>
              </div>
            </div>
          </div>

          <div className="w-full lg:w-1/4">
            <div className="m-1 bg-[#EAEDF0] min-h-44 p-3 gap-4 rounded-lg">
              <div className="flex gap-2 items-center">
                <FaMicrochip size={24} />
                <span className="font-bold">Certification</span>
              </div>
              <div>
                <span className="text-xs">
                CCC, SRRC, CTA, CE
                </span>
              </div>
            </div>
          </div>

        </div>

        <div className="flex justify-center mt-8">
          <a
            href="/devices-pdf/cx20 pro.pdf"
            target="_blank"
            rel="noopener noreferrer"
            className="bg-[#1398F1] py-2 px-12 text-white rounded-full text-2xl lg:text-sm font-bold"
          >
            Download brochure
          </a>
        </div>
      </div>
    </div>
  );
};

export default Specification;
