import React from 'react'

function Fourfeatures() {
    return (
        <div className='xl:py-[90px] lg:py-[90px] md:py-[50px] py-[30px] xl:px-0 lg:px-0 md:px-[20px] px-[20px]'>
            <div className='container mx-auto'>
                <div className='flex'>
                    <div className='bg-[#F3F2F2]'>
                        <p className='xl:text-[42px] lg:text-[42px] md:text-[42px] text-[30px]  font-semibold text-start text-[#222222]'>User-friendly interface

                        </p>
                    </div>
                    <div className='bg-[#F3F2F2]'>
                        4567
                    </div>
                    <div className='bg-[#F3F2F2]'>
                        4567
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Fourfeatures