import React from 'react';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const AutoplaySlider = () => {
  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    // dots: true,               
    arrows: false,
    infinite: true,
  };

  const images = [
    { src: "../../../../../assets/Images/artboard-1-66d6b116a1ff7.webp", alt: "Slide 1" },
    { src: "../../../../../assets/Images/artboard-1-66d6b116a1ff7.webp", alt: "Slide 2" },
    { src: "../../../../../assets/Images/artboard-1-66d6b116a1ff7.webp", alt: "Slide 3" },
    { src: "../../../../../assets/Images/artboard-1-66d6b116a1ff7.webp", alt: "Slide 4" },
    { src: "../../../../../assets/Images/artboard-1-66d6b116a1ff7.webp", alt: "Slide 5" },
  ];

  return (
    <div>
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index}>
            <div className='banner-img-wrapper xl:py-[140px] lg:py-[140px] md:py-[50px] py-[30px] xl:px-0 lg:px-0 md:px-[20px] px-[20px]'>
              <div className='container mx-auto'>
                <div className='flex items-center justify-between'>
                  <div className='w-[50%]'>
                    <img src={image?.src} alt={image.alt} />
                  </div>
                  <div className='w-[50%]'>
                    <div className='max-w-[535px] text-center'>
                      <h1 className='text-[30px] sm:text-[40px] lg:text-[54px] xl:text-[54px] text-[#FF5E14] font-bold'>
                        CX20 SE
                        Windows Powered ECR Terminal
                      </h1>
                      <div className='max-w-[300px] mx-auto'>
                        <p className='text-[#222222] xl:text-[40px] lg:text-[40px] md:text-[20px] text-[16px]'>
                          Efficiency Meets Compatibility
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        ))}
      </Slider>
    </div>
  );
};

export default AutoplaySlider;
