import React from "react";

const Larger = () => {
  return (
    <div className="px-8 lg:px-40 py-12">
      <div>
        <h4 className="text-3xl font-bold">Intuitive cable management </h4>
        <p className="text-sm font-normal pt-4 leading-5">
        Incorporate intuitive cable management with in the triangular design,
        keeping cables organized and out of sight for a clean and professional look.
        </p>
        <div>
          <img src="/banner/AndposC20Se/Intuitive.webp" />
        </div>
      </div>
    </div>
  );
};

export default Larger;
