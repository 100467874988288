import React from "react";
import { FaMicrochip } from "react-icons/fa6";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { BiSolidMemoryCard } from "react-icons/bi";
import { BsSoundwave } from "react-icons/bs";

const Specification = () => {
  return (
    <div className="px-8 lg:px-40 py-12">
      <div>
        <h4 className="text-3xl font-bold">Specifications</h4>
      </div>

      <div>
        <div className="flex flex-wrap w-full pt-12">
          <div className="w-full lg:w-1/4">
            <div className="m-1 bg-[#EAEDF0] min-h-44 p-3 gap-4 rounded-lg">
              <div className="flex gap-2 items-center">
                <FaMicrochip size={24} />
                <span className="font-bold">CPU</span>
              </div>
              <div>
                <span className="text-xs">
                ARM cortex-RS
                </span>
              </div>
            </div>
          </div>

          <div className="w-full lg:w-1/4">
            <div className="m-1 bg-[#EAEDF0] min-h-44 p-3 gap-4 rounded-lg">
              <div className="flex gap-2 items-center">
                <FaMicrochip size={24} />
                <span className="font-bold">Weight</span>
              </div>
              <div>
                <span className="text-xs">
                304 gm
                </span>
              </div>
            </div>
          </div>

          <div className="w-full lg:w-1/4">
            <div className="m-1 bg-[#EAEDF0] min-h-44 p-3 gap-4 rounded-lg">
              <div className="flex gap-2 items-center">
                <FaMicrochip size={24} />
                <span className="font-bold">Connectivity</span>
              </div>
              <div>
                <span className="text-xs">
                4C CAT1
                </span>
              </div>
            </div>
          </div>

          <div className="w-full lg:w-1/4">
            <div className="m-1 bg-[#EAEDF0] min-h-44 p-3 gap-4 rounded-lg">
              <div className="flex gap-2 items-center">
                <FaMicrochip size={24} />
                <span className="font-bold">Lights</span>
              </div>
              <div>
                <span className="text-xs">
                1 Power, 1 dual color status light (green/red)
                </span>
              </div>
            </div>
          </div>

          <div className="w-full lg:w-1/4">
            <div className="m-1 bg-[#EAEDF0] min-h-44 p-3 gap-4 rounded-lg">
              <div className="flex gap-2 items-center">
                <FaMicrochip size={24} />
                <span className="font-bold">Buttons</span>
              </div>
              <div>
                <span className="text-xs">
                Function, Power, volume +, volume -, reset
                </span>
              </div>
            </div>
          </div>

          <div className="w-full lg:w-1/4">
            <div className="m-1 bg-[#EAEDF0] min-h-44 p-3 gap-4 rounded-lg">
              <div className="flex gap-2 items-center">
                <FaMicrochip size={24} />
                <span className="font-bold">Ports</span>
              </div>
              <div>
                <span className="text-xs">
                USB Type-C power supply
                </span>
              </div>
            </div>
          </div>

          <div className="w-full lg:w-1/4">
            <div className="m-1 bg-[#EAEDF0] min-h-44 p-3 gap-4 rounded-lg">
              <div className="flex gap-2 items-center">
                <FaMicrochip size={24} />
                <span className="font-bold">Power Supply (Optional)</span>
              </div>
              <div>
                <span className="text-xs">
                DC 5V/1.0A
                </span>
              </div>
            </div>
          </div>

          <div className="w-full lg:w-1/4">
            <div className="m-1 bg-[#EAEDF0] min-h-44 p-3 gap-4 rounded-lg">
              <div className="flex gap-2 items-center">
                <FaMicrochip size={24} />
                <span className="font-bold">Screen (Optional)</span>
              </div>
              <div>
                <span className="text-xs">
                Displays transaction amount, battery status, 
                volume, signal strength, etc.
                </span>
              </div>
            </div>
          </div>

          <div className="w-full lg:w-1/4">
            <div className="m-1 bg-[#EAEDF0] min-h-44 p-3 gap-4 rounded-lg">
              <div className="flex gap-2 items-center">
                <FaMicrochip size={24} />
                <span className="font-bold">Battery</span>
              </div>
              <div>
                <span className="text-xs">
                2000mAH High capacity lithium battery
                </span>
              </div>
            </div>
          </div>

          <div className="w-full lg:w-1/4">
            <div className="m-1 bg-[#EAEDF0] min-h-44 p-3 gap-4 rounded-lg">
              <div className="flex gap-2 items-center">
                <FaMicrochip size={24} />
                <span className="font-bold"> Announcement type</span>
              </div>
              <div>
                <span className="text-xs">
                Text to speech, and customized phrases
                </span>
              </div>
            </div>
          </div>

          <div className="w-full lg:w-1/4">
            <div className="m-1 bg-[#EAEDF0] min-h-44 p-3 gap-4 rounded-lg">
              <div className="flex gap-2 items-center">
                <FaMicrochip size={24} />
                <span className="font-bold">Environment</span>
              </div>
              <div>
                <span className="text-xs">
                  Operating temperature : -100C~500C
                  Storage temperature : -200C~600C
                  Relative humidity : -100C~95% non-condensing
                </span>
              </div>
            </div>
          </div>

          <div className="w-full lg:w-1/4">
            <div className="m-1 bg-[#EAEDF0] min-h-44 p-3 gap-4 rounded-lg">
              <div className="flex gap-2 items-center">
                <FaMicrochip size={24} />
                <span className="font-bold">What's included</span>
              </div>
              <div>
                <span className="text-xs">
                Speaker unit, User manual, 
                USB charging cable (60cm)
                </span>
              </div>
            </div>
          </div>

          <div className="w-full lg:w-1/4">
            <div className="m-1 bg-[#EAEDF0] min-h-44 p-3 gap-4 rounded-lg">
              <div className="flex gap-2 items-center">
                <FaMicrochip size={24} />
                <span className="font-bold">Speaker</span>
              </div>
              <div>
                <span className="text-xs">
                4Ω3W
                </span>
              </div>
            </div>
          </div>

        </div>

        <div className="flex justify-center mt-8">
          <a
            href="/devices-pdf/ns30.pdf"
            target="_blank"
            rel="noopener noreferrer"
            className="bg-[#1398F1] py-2 px-12 text-white rounded-full text-2xl lg:text-sm font-bold"
          >
            Download brochure
          </a>
        </div>
      </div>
    </div>
  );
};

export default Specification;
