import React from "react";
import { FaMicrochip } from "react-icons/fa6";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { BiSolidMemoryCard } from "react-icons/bi";
import { BsSoundwave } from "react-icons/bs";

const Specification = () => {
  return (
    <div className="px-8 lg:px-40 py-12">
      <div>
        <h4 className="text-3xl font-bold">Specifications</h4>
      </div>

      <div>
        <div className="flex flex-wrap w-full pt-12">
          <div className="w-full lg:w-1/4">
            <div className="m-1 bg-[#EAEDF0] min-h-44 p-3 gap-4 rounded-lg">
              <div className="flex gap-2 items-center">
                <FaMicrochip size={24} />
                <span className="font-bold">Processor</span>
              </div>
              <div>
                <span className="text-xs">Quad-Core, 2.0GHz</span>
              </div>
            </div>
          </div>
          <div className="w-full lg:w-1/4">
            <div className="m-1 bg-[#EAEDF0] min-h-44 p-3 gap-4 rounded-lg">
              <div className="flex gap-2 items-center">
                <AiOutlineLoading3Quarters size={24} />
                <span className="font-bold">OS</span>
              </div>
              <div>
                <span className="text-xs">Android 13</span>
              </div>
            </div>
          </div>
          <div className="w-full lg:w-1/4">
            <div className="m-1 bg-[#EAEDF0] min-h-44 p-3 gap-4 rounded-lg">
              <div className="flex gap-2 items-center">
                <BiSolidMemoryCard size={24} />
                <span className="font-bold">Memory</span>
              </div>
              <div>
                <span className="text-xs">
                4GB RAM + 32GB FLASH
                </span>
                {/* <div>
                  <span className="text-xs">MicroSD Card (up to 2TB)</span>
                </div> */}
              </div>
            </div>
          </div>
          <div className="w-full lg:w-1/4">
            <div className="m-1 bg-[#EAEDF0] min-h-44 p-3 gap-4 rounded-lg">
              <div className="flex gap-2 items-center">
                <BsSoundwave size={24} />
                <span className="font-bold">Keypad</span>
              </div>
              <div>
                <span className="text-xs">1 Power key with indicator light (red&blue) & 2 volumes keys</span>
              </div>
              
            </div>
          </div>
          <div className="w-full lg:w-1/4">
            <div className="m-1 bg-[#EAEDF0] min-h-44 p-3 gap-4 rounded-lg">
              <div className="flex gap-2 items-center">
                <FaMicrochip size={24} />
                <span className="font-bold">Main Display</span>
              </div>
              <div>
                <span className="text-xs">
                15.6'', 1920x1080 HD, FHD
                capacitive multi-touch
                </span>
              </div>
            </div>
          </div>

          <div className="w-full lg:w-1/4">
            <div className="m-1 bg-[#EAEDF0] min-h-44 p-3 gap-4 rounded-lg">
              <div className="flex gap-2 items-center">
                <FaMicrochip size={24} />
                <span className="font-bold">Speaker</span>
              </div>
              <div>
                <span className="text-xs">
                5W,
                100dB@1m,
                Dual Speaker
                </span>
              </div>
            </div>
          </div>

          <div className="w-full lg:w-1/4">
            <div className="m-1 bg-[#EAEDF0] min-h-44 p-3 gap-4 rounded-lg">
              <div className="flex gap-2 items-center">
                <FaMicrochip size={24} />
                <span className="font-bold">Headset</span>
              </div>
              <div>
                <span className="text-xs">
                3.5mm audio jack(CTIA&OTMP)
                </span>
              </div>
            </div>
          </div>

          <div className="w-full lg:w-1/4">
            <div className="m-1 bg-[#EAEDF0] min-h-44 p-3 gap-4 rounded-lg">
              <div className="flex gap-2 items-center">
                <FaMicrochip size={24} />
                <span className="font-bold">MIC</span>
              </div>
              <div>
                <span className="text-xs">
                Dual Microphone
                </span>
              </div>
            </div>
          </div>

          <div className="w-full lg:w-1/4">
            <div className="m-1 bg-[#EAEDF0] min-h-44 p-3 gap-4 rounded-lg">
              <div className="flex gap-2 items-center">
                <FaMicrochip size={24} />
                <span className="font-bold">Connectivity</span>
              </div>
              <div>
                <span className="text-xs">
                1000M,
                IEEE802.11 a/b/g/n/ac 2.4GHz and 5GHz
                Wi-Fi6(optional)
                Bluetooth 5.0，support BLE
                </span>
              </div>
            </div>
          </div>

          <div className="w-full lg:w-1/4">
            <div className="m-1 bg-[#EAEDF0] min-h-44 p-3 gap-4 rounded-lg">
              <div className="flex gap-2 items-center">
                <FaMicrochip size={24} />
                <span className="font-bold">Camera</span>
              </div>
              <div>
                <span className="text-xs">
                Optional 1 : NONE
                Optional 2 : 0.3M pixels, QR scan (Only supported on the 10.1'' Premium CFD)
                Optional 3 : Facial recognition ability camera (USB device, plug into Main display edge expansion port)
                </span>
              </div>
            </div>
          </div>

          <div className="w-full lg:w-1/4">
            <div className="m-1 bg-[#EAEDF0] min-h-44 p-3 gap-4 rounded-lg">
              <div className="flex gap-2 items-center">
                <FaMicrochip size={24} />
                <span className="font-bold">NFC (Optional)</span>
              </div>
              <div>
                <span className="text-xs">
                Android NFC,
                ISO / IEC 14443、ISO15693 compliant,
                support Type A / B, Mifare，Felica（Only supported on the 10.1'' Premium CFD）
                </span>
              </div>
            </div>
          </div>

          <div className="w-full lg:w-1/4">
            <div className="m-1 bg-[#EAEDF0] min-h-44 p-3 gap-4 rounded-lg">
              <div className="flex gap-2 items-center">
                <FaMicrochip size={24} />
                <span className="font-bold">Ports</span>
              </div>
              <div>
                <span className="text-xs">
                  1*USB Typec-C debug port, 1*Power port,
                  4*USB Type-A 2.0, 1*USB Type-A 3.0
                  1*RJ22 Serial Port, 1*MicroSD slot
                  1*RJ45 Ethernet port
                  1*RJ11 Cash drawer port
                  1*3.5mm Audio jack
                </span>
              </div>
            </div>
          </div>

          <div className="w-full lg:w-1/4">
            <div className="m-1 bg-[#EAEDF0] min-h-44 p-3 gap-4 rounded-lg">
              <div className="flex gap-2 items-center">
                <FaMicrochip size={24} />
                <span className="font-bold">Accessories
                </span>
              </div>
              <div>
                <span className="text-xs">
                Screen edge expansion (Micro USB)
                </span>
              </div>
            </div>
          </div>

          <div className="w-full lg:w-1/4">
            <div className="m-1 bg-[#EAEDF0] min-h-44 p-3 gap-4 rounded-lg">
              <div className="flex gap-2 items-center">
                <FaMicrochip size={24} />
                <span className="font-bold">Customer display</span>
              </div>
              <div>
                <span className="text-xs">
                1: None
                2: 10.1" Standard CFD (1280x800 display only)
                3: 10.1" Premium CFD (1280x800, Touch panel, QR Camera, NFC)
                </span>
              </div>
            </div>
          </div>

          <div className="w-full lg:w-1/4">
            <div className="m-1 bg-[#EAEDF0] min-h-44 p-3 gap-4 rounded-lg">
              <div className="flex gap-2 items-center">
                <FaMicrochip size={24} />
                <span className="font-bold">Environment</span>
              </div>
              <div>
                <span className="text-xs">
                Operating temperature：0℃ to +40℃
                Storage temperature：-20℃ to +60℃
                Operating humidity：10% to 90% non-condensing
                </span>
              </div>
            </div>
          </div>

          <div className="w-full lg:w-1/4">
            <div className="m-1 bg-[#EAEDF0] min-h-44 p-3 gap-4 rounded-lg">
              <div className="flex gap-2 items-center">
                <FaMicrochip size={24} />
                <span className="font-bold">Protection</span>
              </div>
              <div>
                <span className="text-xs">
                IP41，
                1.5m drop
                </span>
              </div>
            </div>
          </div>

          <div className="w-full lg:w-1/4">
            <div className="m-1 bg-[#EAEDF0] min-h-44 p-3 gap-4 rounded-lg">
              <div className="flex gap-2 items-center">
                <FaMicrochip size={24} />
                <span className="font-bold">PSU</span>
              </div>
              <div>
                <span className="text-xs">
                19V/3.42A
                </span>
              </div>
            </div>
          </div>

          <div className="w-full lg:w-1/4">
            <div className="m-1 bg-[#EAEDF0] min-h-44 p-3 gap-4 rounded-lg">
              <div className="flex gap-2 items-center">
                <FaMicrochip size={24} />
                <span className="font-bold">Certification</span>
              </div>
              <div>
                <span className="text-xs">
                CE, CB, FCC, IC, WEEE, RoHS
                </span>
              </div>
            </div>
          </div>

        </div>

        <div className="flex justify-center mt-8">
          <a
            href="/devices-pdf/ns20 s.pdf"
            target="_blank"
            rel="noopener noreferrer"
            className="bg-[#1398F1] py-2 px-12 text-white rounded-full text-2xl lg:text-sm font-bold"
          >
            Download brochure
          </a>
        </div>
      </div>
    </div>
  );
};

export default Specification;
