import React from 'react'

function Portability() {
    return (
        <div>
            <div className='portability-bg-img xl:pt-[100px] lg:pt-[100px] md:pt-[50px] pt-[30px] xl:px-0 lg:px-0 md:px-[20px] px-[20px]'>
                <div className='container mx-auto'>
                    <div className='flex xl:flex-row lg:flex-row md:flex-row flex-col items-start'>
                        <div className='xl:w-[50%] lg:w-[50%] md:w-[50%] w-[100%]'>
                            <div className='max-w-[400px]'>
                                <p className='xl:text-[42px] lg:text-[42px] md:text-[42px] text-[30px]  font-semibold text-start text-white'>
                                    Portability and LongBattery Life.
                                </p>
                                <p className='text-white xl:text-[16px] lg:text-[16px] md:text-[16px] text-black font-normal mt-[25px] leading-[29px]'>
                                    Designed for on-the-go transactions,the POS terminal comes with a largebattery to support extended usage,allowing merchants to processpayments without worrying aboutconstant recharging.
                                </p>
                            </div>
                        </div>
                        <div className='xl:w-[50%] lg:w-[50%] md:w-[50%] w-[100%]'>
                            <div className=''>
                                <img className=' xl:mx-0 lg:mx-0 mx-auto xl:mt-0 lg:mt-0 md:mt-0 mt-[30px] mb-[-30px]' src="../../../../../assets/Images/Group 101.png"></img>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
     <div className='container mx-auto'>
     <div className='bg-white xl:py-[100px] lg:py-[100px] md:py-[50px] pt-[30px] xl:px-0 lg:px-0 md:px-[20px] px-[20px] '>
            <p className='xl:text-[42px] lg:text-[42px] text-[30px] md:text-[48px] font-semibold text-center text-black'>
Seamless Connectivity.
</p>
<p className='text-black xl:text-[16px] lg:text-[16px] md:text-[14px] text-black font-normal  text-center mt-[25px] leading-[29px]'>
Stay connected with built-in connectivity options such as 4G, Wi-Fi and Bluetooth, facilitating easy
integration with other devices and systems.
</p>

            </div>
     </div>
        </div>
    )
}

export default Portability