import React from "react";

const VersatilePorts = () => {
  return (
    <div >
      <div className="relative">
        <div>
          <img src="/banner/versatile.webp" alt="" className="min-h-[580px] lg:min-h-0" />
        </div>

        <div className="absolute top-16  right-14 px-8">
          <div className="max-w-[550px]">
            <span className="text-[2rem] text-[#1398F1] font-bold">
              Versatile ports for enhanced connectivity
            </span>

            <p className="text-white pt-2">
              Expand your device's capabilities with a wide range of external
              ports, providing more possibilities beyond your everyday needs.
            </p>

            <div className="flex flex-wrap pt-8">
              <div className="w-full md:w-1/2">
                <ul className="text-white text-lg">
                  <li>4xUSB Type-A ports</li>
                  <li>1xRJ22 Serial port</li>
                  <li>1xAudio jack</li>
                  <li>1xRJ11 port for cash drawer</li>
                  <li>1xMicro USB debug port</li>
                </ul>
              </div>
              <div className="w-full md:w-1/2">
                <ul className="text-white text-lg">
                  <li>1xRJ45 Ethernet port</li>
                  <li>1xMicro SD slot</li>
                  <li>1xPower port</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VersatilePorts;
