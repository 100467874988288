import React from 'react'



function Stabilitysection() {
    return (
        <div>
            <div className='container mx-auto xl:py-[100px] lg:py-[100px] md:py-[50px] py-[30px] xl:px-0 lg:px-0 md:px-[20px] px-[20px]'>
                <div className='flex xl:flex-row lg:flex-row md:flex-row flex-col xl:gap-0 lg:gap-0 md:gap-0 gap-4 items-center justify-between'>
                    <div className='frame-bg-img xl:w-[49%]  lg:w-[49%]  md:w-[49%]  w-[100%] xl:py-[60px] lg:py-[60px] md:py-[30px] py-[25px] xl:px-[50px] lg:px-[50px] md:px-[20px] px-[20px] rounded-[8px]'>
                        <div className='max-w-[360px] mx-auto'>
                            <img src="../../../../../assets/Images/frame (1).png"></img>
                        </div>
                        <div className='xl:mt-[60px] lg:mt-[60px] md:mt-[30px] mt-[20px]'>
                            <p className='text-[#222222] xl:text-[42px] lg:text-[42px] md:text-[30px] text-[30px] font-semibold text-white'>
                                Stability And Durability
                            </p>
                            <p className='text-[16px] text-white max-w-[485px] w-full'>
                                The triangular structure Contributes to the stability
                                and durability, ensuring it can withstand everyday
                                use and potential minor impacts.
                            </p>
                        </div>
                    </div>
                    <div className='xl:w-[50%] lg:w-[50%] md:w-[50%] w-[100%] flex flex-col gap-[19px]'>
                        <div className='cutting-img xl:py-[60px] lg:py-[60px] md:py-[30px] py-[20px] px-[35px] rounded-[8px]'>
                            <p className='xl:text-[42px] lg:text-[42px] md:text-[30px] text-[30px] font-semibold text-white'>
                                Stay On The Cutting edge
                            </p>
                            <p className='text-[16px] text-white max-w-[485px]'>
                                Experience the Freshest Features And
                                enhancements with our product's
                                compatibility with Android 13
                                Advancements. Elevate your mobile
                                experience to the Next level.
                            </p>
                        </div>
                        <div className='frame-bg-img py-[20px] rounded-[8px]'>
                            <div className='xl:max-w-[268px] lg:max-w-[268px] md:max-w-[177px] mx-auto'>
                                <img className='mx-auto' src="../../../../../assets/Images/c20 pro 11 4.png"></img>
                            </div>
                        </div>
                    </div>
                </div>
                <div className=''>

                </div>
            </div>
            <div className='display-option xl:py-[63px] lg:py-[63px] md:py-[30px] py-[20px]'>
                <div className='container mx-auto'>
                    <div className='flex items-center'>
                        <div className='xl:w-[50%] lg:w-[50%] md:w-[50%] w-[100%]'>
                            <div className='mb-[70px]'>
                                <div className='max-w-[510px]'>
                                    <p className='xl:text-[42px] lg:text-[42px] md:text-[30px] text-[30px] font-semibold text-[#FF5E14]'>Diversift your viewing experience
                                        with multiple display options</p>
                                </div>
                            </div>
                            <div className='border-[#F3755B] border-2 rounded-[8px] py-[13px] px-[20px] mb-[20px] max-w-[509px] w-[100%]'>
                                <label className='text-[16px] font-bold'>
                                    None
                                </label>
                                <p className='font-normal text-[16px] mt-1'>
                                    (single Screen)
                                </p>
                            </div>
                            <div className='border-[#F3755B] border-2 rounded-[8px] py-[13px] px-[20px] mb-[20px]  max-w-[509px] w-[100%]'>
                                <label className='text-[16px] font-bold'>
                                    Standard CFD
                                </label>
                                <p className='font-normal text-[16px] mt-1'>
                                    (10.1 “ HD 1280X800 Display Only)
                                </p>
                            </div>
                            <div className='border-[#F3755B] border-2 rounded-[8px] py-[13px] px-[20px] mb-[20px]  max-w-[509px] w-[100%]'>
                                <label className='text-[16px] font-bold'>
                                    Premium CFD
                                </label>
                                <p className='font-normal text-[16px] mt-1'>
                                    (10.1 “ HD 1280X800 Touch Panel, QR  Camera NFC)
                                </p>
                            </div>
                            <div className='border-[#F3755B] border-2 rounded-[8px] py-[13px] px-[20px] mb-[20px]  max-w-[509px] w-[100%]'>
                                <label className='text-[16px] font-bold'>
                                    P30-T
                                </label>
                                <p className='font-normal text-[16px] mt-1'>
                                    (PCI Payment Tablate)
                                </p>
                            </div>
                        </div>
                        <div className='xl:w-[50%] lg:w-[50%] md:w-[50%] w-[100%]'>
                            <img className='mx-auto' src="../../../../../assets/Images/C20  Lite 1.png">
                            </img>
                        </div>
                    </div>
                </div>
            </div>
            <div className='bg-wrapper bg-[#ff5e14] w-[100%] h-[100bvh] xl:py-[100px] lg:py-[100px] md:py-[50px] py-[30px] xl:px-0 lg:px-0 md:px-[20px] px-[20px]'>
                <div className='container mx-auto'>
                    <p className='xl:text-[42px] lg:text-[42px] md:text-[30px] text-[30px] font-semibold text-center text-white mb-[20px]'>
                        Large, clear and fluid.
                    </p>
                    <p className='text-[20px] font-medium max-w-[600px] mx-auto text-white text-center'>
                        With its 15.6" main screen and a high resolution of 1920×1080,
                        C20 Pro provides exceptional image quality that fully meets
                        your visual expectations in any environment.
                    </p>
                </div>
            </div>

        </div>
    )
}

export default Stabilitysection