import React from 'react'
import Moderndesign from './Moderndesign'
import Userfriendly from './Userfriendly'
import Fourfeatures from './Fourfeatures'

function Pbanner() {
  return (
  <div>
      <div>Pbanner</div>
      <Moderndesign/>
      <Userfriendly/>
      <Fourfeatures/>
  </div>
  )
}

export default Pbanner