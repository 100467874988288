import React from "react";
import { FaMicrochip } from "react-icons/fa6";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { BiSolidMemoryCard } from "react-icons/bi";
import { BsSoundwave } from "react-icons/bs";

const Specification = () => {
  return (
    <div className="px-8 lg:px-40 py-12">
      <div>
        <h4 className="text-3xl font-bold">Specifications</h4>
      </div>

      <div>
        <div className="flex flex-wrap w-full pt-12">
          <div className="w-full lg:w-1/4">
            <div className="m-1 bg-[#EAEDF0] min-h-44 p-3 gap-4 rounded-lg">
              <div className="flex gap-2 items-center">
                <FaMicrochip size={24} />
                <span className="font-bold">Processor</span>
              </div>
              <div>
                <span className="text-xs">Quad-Core, 2.0GHz</span>
              </div>
            </div>
          </div>
          <div className="w-full lg:w-1/4">
            <div className="m-1 bg-[#EAEDF0] min-h-44 p-3 gap-4 rounded-lg">
              <div className="flex gap-2 items-center">
                <AiOutlineLoading3Quarters size={24} />
                <span className="font-bold">OS</span>
              </div>
              <div>
                <span className="text-xs">Android 13</span>
              </div>
            </div>
          </div>
          <div className="w-full lg:w-1/4">
            <div className="m-1 bg-[#EAEDF0] min-h-44 p-3 gap-4 rounded-lg">
              <div className="flex gap-2 items-center">
                <BiSolidMemoryCard size={24} />
                <span className="font-bold">Memory</span>
              </div>
              <div>
                <span className="text-xs">
                  2GB RAM+16GB FLASH 4GB RAM+32GB FLASH (Optional)
                </span>
                <div>
                  <span className="text-xs">MicroSD Card (up to 2TB)</span>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full lg:w-1/4">
            <div className="m-1 bg-[#EAEDF0] min-h-44 p-3 gap-4 rounded-lg">
              <div className="flex gap-2 items-center">
                <BsSoundwave size={24} />
                <span className="font-bold">Audio</span>
              </div>
              <div>
                <span className="text-xs">Speaker: 3W speaker</span>
              </div>
              <div>
                <span className="text-xs">Headset: 3.5mm audio jack</span>
              </div>
            </div>
          </div>
          <div className="w-full lg:w-1/4">
            <div className="m-1 bg-[#EAEDF0] min-h-44 p-3 gap-4 rounded-lg">
              <div className="flex gap-2 items-center">
                <FaMicrochip size={24} />
                <span className="font-bold">Main Display</span>
              </div>
              <div>
                <span className="text-xs">
                  15.6", 1366 x768, HD 15.6", 1920x1080, FHD (Optional)
                  capacitive multi-touch
                </span>
              </div>
            </div>
          </div>

          <div className="w-full lg:w-1/4">
            <div className="m-1 bg-[#EAEDF0] min-h-44 p-3 gap-4 rounded-lg">
              <div className="flex gap-2 items-center">
                <FaMicrochip size={24} />
                <span className="font-bold">Buttons</span>
              </div>
              <div>
                <span className="text-xs">
                Power button
                </span>
              </div>
            </div>
          </div>

          <div className="w-full lg:w-1/4">
            <div className="m-1 bg-[#EAEDF0] min-h-44 p-3 gap-4 rounded-lg">
              <div className="flex gap-2 items-center">
                <FaMicrochip size={24} />
                <span className="font-bold">Ports</span>
              </div>
              <div>
                <span className="text-xs">
                1*Micro USB, debug port,
                4*USB Type-A 2.0, 
                1*RJ22 Serial port, 1*MicroSD slot, 1*Power port, 1*RJ45 Ethernet port, 1*RJ11 Cash drawer port, 
                </span>
              </div>
            </div>
          </div>

          <div className="w-full lg:w-1/4">
            <div className="m-1 bg-[#EAEDF0] min-h-44 p-3 gap-4 rounded-lg">
              <div className="flex gap-2 items-center">
                <FaMicrochip size={24} />
                <span className="font-bold">Communication</span>
              </div>
              <div>
                <span className="text-xs">
                ETH 100M,
                Wi-Fi : 2.4GHz
                Bluetooth 4.2,
                support BLE
                </span>
              </div>
            </div>
          </div>

          <div className="w-full lg:w-1/4">
            <div className="m-1 bg-[#EAEDF0] min-h-44 p-3 gap-4 rounded-lg">
              <div className="flex gap-2 items-center">
                <FaMicrochip size={24} />
                <span className="font-bold">Customer display</span>
              </div>
              <div>
                <span className="text-xs">
                Optional 1 : None (Single Display)
                Optional 2 : 10.1'', 1280x800
                </span>
              </div>
            </div>
          </div>

          <div className="w-full lg:w-1/4">
            <div className="m-1 bg-[#EAEDF0] min-h-44 p-3 gap-4 rounded-lg">
              <div className="flex gap-2 items-center">
                <FaMicrochip size={24} />
                <span className="font-bold">Environment</span>
              </div>
              <div>
                <span className="text-xs">
                Operating Temperature:
                0℃ to +40℃
                Storage temperature:
                -20℃ to +60℃
                Operating humidity:
                10% to 90% non-condensing
                </span>
              </div>
            </div>
          </div>

          <div className="w-full lg:w-1/4">
            <div className="m-1 bg-[#EAEDF0] min-h-44 p-3 gap-4 rounded-lg">
              <div className="flex gap-2 items-center">
                <FaMicrochip size={24} />
                <span className="font-bold">Certification</span>
              </div>
              <div>
                <span className="text-xs">
                CE, CB, FCC, IC, WEEE, RoHS
                </span>
              </div>
            </div>
          </div>

        </div>

        <div className="flex justify-center mt-8">
          <a
            href="/devices-pdf/c20 lite.pdf"
            target="_blank"
            rel="noopener noreferrer"
            className="bg-[#1398F1] py-2 px-12 text-white rounded-full text-2xl lg:text-sm font-bold"
          >
            Download brochure
          </a>
        </div>
      </div>
    </div>
  );
};

export default Specification;
