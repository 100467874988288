import React from "react";
import Banner from "../../Component/Ns20s/Banner";
import KeyFeatures from "../../Component/Ns20s/KeyFeatures";
import EveryPayment from "../../Component/Ns20s/EveryPayment";
import Larger from "../../Component/Ns20s/Larger";
import VersatilePorts from "../../Component/Ns20s/VersatilePorts";
import Specification from "../../Component/Ns20s/Specification";
// import FooterNew from "../../Component/FooterNew";

const Ns20s = () => {
  return (
    <div>
      <div>
        <Banner />
      </div>
      <div>
        <KeyFeatures />
      </div>

      <div>
        <EveryPayment />
      </div>
      <div>
        <Larger />
      </div>

      <VersatilePorts />

      <div>
        <Specification />
      </div>

      {/* <FooterNew /> */}
    </div>
  );
};

export default Ns20s;
