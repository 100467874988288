import React from "react";
import Slider from "react-slick";

// Make sure to create and include this CSS file

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import required modules
import {Autoplay, Navigation } from "swiper/modules";

function HeroSection() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    responsive: [
      {
        breakpoint: 320, // Small mobile devices
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          margin: 10, // Adjust margin for small screens if needed
        },
      },
      {
        breakpoint: 480, // Larger mobile devices
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          margin: 20, // Adjust margin for slightly larger screens
        },
      },
      {
        breakpoint: 768, // Tablets and small laptops
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          margin: 30, // Adjust margin for tablets and small laptops
        },
      },
      {
        breakpoint: 1024, // Laptops and larger tablets
        settings: {
          slidesToShow: 1,
          slidesToScroll: 3,
          centerMode: true,
          margin: 40, // Adjust margin for larger tablets and laptops
        },
      },
    ],
  };

  return (
    <>
      <div className="overflow-hidden">        
        <Swiper navigation={false} modules={[Autoplay, Navigation]} autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }} className="mySwiper">
          <SwiperSlide>
            <div className="bg-custom-bannerimage md:relative xl:py-[110px] lg:py-[110px] md:py-[50px] py-[30px] xl:px-0 lg:px-0 md:px-[20px] px-[20px] xl:h-[70vh] lg:h-[70vh] md:h-[50vh]">
              <div className="container mx-auto">
                <div className="flex ">
                  <div className="xl:w-[50%] lg:w-[50%] md:w-[50%] w-[100%]">
                    <div className=" xl:max-w-[500x] lg:max-w-[500x] md:max-w-[500x] max-w-[100%]">
                      <h1 className="xl:text-[54px] lg:text-[54px] md:text-[34px] text-[24px] text-[#FF5E14] font-bold">
                        {/* Introducing AndPOS */}
                      </h1>
                      <p className="text-[20px] sm:text-[30px] lg:text-[40px] xl:text-[40px] leading-10">
                        {/* <span className="text-[#FF5E14]">Smart POS</span>{" "}
                        Terminal <br /> */}
                        {/* Simplify your QR Code Payments with AndPOS soundbox                         */}
                      </p>

                      <p className="text-sm sm:text-base lg:text-lg xl:text-xl text-[#777777] pt-7">
                        {/* Go with our Merchant Acquiring Solutions Let payments{" "} */}
                        {/* <br /> technology grow your world. */}
                      </p>
                    </div>
                  </div>
                  <div className="xl:w-[50%] lg:w-[50%] md:w-[50%] w-[100%] hidden">
                    {/* <img
                      className="mx-auto w-[70%] hidden"
                      src="../../../../../assets/Images/p30.png"
                      alt="product img"
                    ></img> */}

                    <img
                      className="mx-auto w-[70%] hidden"
                      src="../../../../../assets/Images/andpos pos machine banner.jpg"
                      alt="product img"
                    ></img>

                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="bg-custom-bannerimageone md:relative xl:py-[110px] lg:py-[110px] md:py-[50px] py-[30px] xl:px-0 lg:px-0 md:px-[20px] px-[20px] xl:h-[70vh] lg:h-[70vh] md:h-[50vh]">
              <div className="container mx-auto">
                <div className="flex ">
                  <div className="xl:w-[50%] lg:w-[50%] md:w-[50%] w-[100%]">
                    <div className=" xl:max-w-[500x] lg:max-w-[500x] md:max-w-[500x] max-w-[100%]">
                      <h1 className="xl:text-[54px] lg:text-[54px] md:text-[34px] text-[24px] text-[#FF5E14] font-bold text-white">
                        {/* Introducing AndPOS */}
                      </h1>
                      <p className="text-[20px] sm:text-[30px] lg:text-[40px] xl:text-[40px] leading-10 text-white">
                        {/* <span className="text-[#FF5E14]">Smart POS</span>{" "}
                        Terminal <br /> */}
                        {/* Seamless card payments with Andpos POS machines */}
                      </p>

                      {/* <p className="text-sm sm:text-base lg:text-lg xl:text-xl text-[#777777] pt-7">
                        Go with our Merchant Acquiring Solutions Let payments{" "}
                        <br /> technology grow your world.
                      </p> */}
                    </div>
                  </div>
                  <div className="xl:w-[50%] lg:w-[50%] md:w-[50%] w-[100%] hidden">
                    {/* <img
                      className="mx-auto w-[70%] hidden"
                      src="../../../../../assets/Images/p30.png"
                      alt="product img"
                    ></img> */}
                    <img
                      className="mx-auto w-[70%] hidden"
                      src="../../../../../assets/Images/andpos soubox brochure.jpg"
                      alt="product img"
                    ></img>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="bg-custom-bannerimagetwo md:relative xl:py-[110px] lg:py-[110px] md:py-[50px] py-[30px] xl:px-0 lg:px-0 md:px-[20px] px-[20px] xl:h-[70vh] lg:h-[70vh] md:h-[50vh]">
              <div className="container mx-auto">
                <div className="flex ">
                  <div className="xl:w-[50%] lg:w-[50%] md:w-[50%] w-[100%]">
                    <div className=" xl:max-w-[500x] lg:max-w-[500x] md:max-w-[500x] max-w-[100%]">
                      <h1 className="xl:text-[54px] lg:text-[54px] md:text-[34px] text-[24px] text-[#FF5E14] font-bold">
                        {/* Introducing AndPOS */}
                      </h1>
                      
                      <p className="text-[20px] sm:text-[30px] lg:text-[40px] xl:text-[40px] leading-10">
                        {/* <span className="text-[#FF5E14]">Smart POS</span>{" "}
                        Terminal <br /> */}
                        {/* Simple, Fast and Secure Digital Payments  */}
                      </p>

                      <p className="text-sm sm:text-base lg:text-lg xl:text-xl text-[#777777] pt-7">
                        {/* Go with our Merchant Acquiring Solutions Let payments{" "}
                        <br /> technology grow your world. */}
                      </p>
                    </div>
                  </div>
                  <div className="xl:w-[50%] lg:w-[50%] md:w-[50%] w-[100%] hidden">
                    {/* <img
                      className="mx-auto w-[70%] hidden"
                      src="../../../../../assets/Images/p30.png"
                      alt="product img"
                    ></img> */}

                    <img
                      className="mx-auto w-[70%] hidden"
                      src="../../../../../assets/Images/digital payment banner.jpg"
                      alt="product img"
                    ></img>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
         
        </Swiper>
      </div>
    </>
  );
}

export default HeroSection;
