import React from 'react';
import ReactStars from "react-rating-stars-component";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Testimonial() {
    const ratingChanged = (newRating) => {
        console.log(newRating);
    };
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        centerMode: true,
        autoplay: false,
        autoplaySpeed: 3000,
        arrows: false,
        responsive: [
            {
                breakpoint: 320, // Small mobile devices
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    margin: 10, // Adjust margin for small screens if needed
                }
            },
            {
                breakpoint: 480, // Larger mobile devices
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    margin: 20, // Adjust margin for slightly larger screens
                }
            },
            {
                breakpoint: 768, // Tablets and small laptops
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    centerMode: true,
                    margin: 30, // Adjust margin for tablets and small laptops
                }
            },
            {
                breakpoint: 1024, // Laptops and larger tablets
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    centerMode: true,
                    margin: 40, // Adjust margin for larger tablets and laptops
                }
            }
        ]
    };

    return (
        <div className='bg-[#F5E7E5]  mt-4 rounded-tl-[48px] rounded-tr-[48px] lg:py-3 overflow-hidden'>
            <div className="container mx-auto lg:px-10  w-full h-full  lg:py-16 lg:my-5">
                <div className='w-full text-center'>
                    <h3 className='text-[#464646] xl:mt-2 lg:mt-2 mt-4 font-semibold text-lg'>Our Testimonial</h3>
                    <h1 className='text-[#464646] font-semibold text-xl sm:text-2xl lg:text-3xl mt-1'>
                        What Our Happy Customers Say
                    </h1>
                    <p className='text-[#464646] font-normal mt-2 text-sm md:text-base'>
                        This is what the Client said to us, for experience using POS
                    </p>
                    <p className='text-[#464646] font-normal text-sm md:text-base'>
                        System on their Business
                    </p>
                </div>
                <div className=' gap-6 mt-10 w-full  md:w-10/12 mx-auto'>
                    <Slider {...settings} className=''>
                        <div className='relative mt-[35px] '>
                            <div className='absolute shadow top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex justify-center items-center bg-white rounded-full w-16 h-16'>
                                <img
                                    src='/assets/Contact/Vector.png'
                                    alt='icon'
                                    className='w-8 h-8  flex justify-center'
                                />
                            </div>
                            <div className='bg-white p-8 shadow-md overflow-hidden mx-4 min-h-[340px]'>
                                <p className='text-xs md:text-base relative  min-h-[210px]'>
                                    The AndPos C20Lite has totally changed the manner in which we handle exchanges. Its lightning-quick execution and smooth plan make it an unquestionable necessity for any cutting edge retail business.
                                </p>
                                <div className='flex flex-col items-center'>
                                    <ReactStars
                                        count={5}
                                        onChange={ratingChanged}
                                        size={24}
                                        activeColor="#ffd700"
                                    />
                                    <p className='text-[#4DC8F4]'>Rajesh Sharma</p>
                                    <h3 className='text-md'>Retail location Proprietor</h3>
                                </div>
                            </div>
                        </div>
                        <div className='relative mt-[35px]'>
                            <div className='absolute shadow top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex justify-center items-center bg-white rounded-full w-16 h-16'>
                                <img
                                    src='/assets/Contact/Vector.png'
                                    alt='icon'
                                    className='w-8 h-8 flex justify-center'
                                />
                            </div>
                            <div className='bg-white p-8 shadow-md overflow-hidden mx-4 min-h-[340px]'>
                                <p className='text-sm md:text-base relative min-h-[210px]'>
                                    The AndPos MS20 SE stands apart with its extraordinary battery duration and smooth plan. It's a trustworthy ally for our business on occupied days.
                                </p>
                                <div className='flex flex-col items-center'>
                                    <ReactStars
                                        count={5}
                                        onChange={ratingChanged}
                                        size={24}
                                        activeColor="#ffd700"
                                    />
                                    <p className='text-[#4DC8F4]'>Kavita Jain</p>
                                    <h3 className='text-md'>Shop Proprietor</h3>
                                </div>
                            </div>
                        </div>
                        <div className='relative mt-[35px] '>
                            <div className='absolute shadow top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex justify-center items-center bg-white rounded-full w-16 h-16'>
                                <img
                                    src='/assets/Contact/Vector.png'
                                    alt='icon'
                                    className='w-8 h-8 flex justify-center'
                                />
                            </div>
                            <div className='bg-white p-8 shadow-md overflow-hidden mx-4 min-h-[340px]'>
                                <p className='text-xs md:text-base relative min-h-[210px]'>
                                    Its smaller size and magnificent execution make the AndPos an incredible fit for our portable business. Exceptionally versatile and solid in their services.
                                </p>
                                <div className='flex flex-col items-center'>
                                    <ReactStars
                                        count={5}
                                        onChange={ratingChanged}
                                        size={24}
                                        activeColor="#ffd700"
                                    />
                                    <p className='text-[#4DC8F4]'>Ashish Kumar</p>
                                    <h3 className='text-md'>Versatile Seller</h3>
                                </div>
                            </div>
                        </div>
                        <div className='relative mt-[35px]'>
                            <div className='absolute shadow top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex justify-center items-center bg-white rounded-full w-16 h-16'>
                                <img
                                    src='/assets/Contact/Vector.png'
                                    alt='icon'
                                    className='w-8 h-8 flex justify-center'
                                />
                            </div>
                            <div className='bg-white p-8 shadow-md overflow-hidden mx-4 min-h-[340px]'>
                                <p className='text-xs md:text-base relative min-h-[210px]'>
                                    We've been involved with the AndPos for NS30 Pro soundbox and it has shown to be solid and have great sound quality. Its adaptability and execution are unequaled for all the payments done on it.
                                </p>
                                <div className='flex flex-col items-center'>
                                    <ReactStars
                                        count={5}
                                        onChange={ratingChanged}
                                        size={24}
                                        activeColor="#ffd700"
                                    />
                                    <p className='text-[#4DC8F4]'>Priya Mehra</p>
                                    <h3 className='text-md'>General store Supervisor</h3>
                                </div>
                            </div>
                        </div>
                    </Slider>

                </div>
            </div>
        </div>
    );
}

export default Testimonial;
