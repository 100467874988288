import React from "react";
import Banner from "../../Component/Hs20/Banner";
import KeyFeatures from "../../Component/Hs20/KeyFeatures";
import EveryPayment from "../../Component/Hs20/EveryPayment";
import Larger from "../../Component/Hs20/Larger";
import VersatilePorts from "../../Component/Hs20/VersatilePorts";
import Specification from "../../Component/Hs20/Specification";
// import FooterNew from "../../Component/FooterNew";

const Hs20 = () => {
  return (
    <div>
      <div>
        <Banner />
      </div>
      <div>
        <KeyFeatures />
      </div>

      <div>
        <EveryPayment />
      </div>
      <div>
        <Larger />
      </div>
      <VersatilePorts />
      <div>
        <Specification />
      </div>

      {/* <FooterNew /> */}
    </div>
  );
};

export default Hs20;
