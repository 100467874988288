import ProductsCard from "../common/ProductsCard";
const OurProducts = () => {

  const cardData = [
      {
        image: "../../../assets/Images/c20 lite-1.png",
        CardHeading: "AndPos C20 Lite",
        // link:"/andpos-c20-lite",
        content:
          "Elevate user experience to new heights with futuristic terminal.",
      },
      {
      image: "../../../assets/Images/c20 se.png",
      CardHeading: "AndPos C20 SE",
      // link:"/andpos-c20-se",
      content: "Unleash your terminal's potential with unshakeable support",
    },
    {
      image: "../../../assets/Images/cx20 pro-2.png",
      CardHeading: "AndPos Cx20 Windows",
      // link:"/andpos-cx20-windows",
      content: "Windows Powered ECR Terminal Efficiency Meets Compatibility",
    },
    {
      image: "../../../assets/Images/c20 pro-2.png",
      CardHeading: "AndPos C20 Pro",
      // link:"/andpos-c20-pro",
      content:"Elevate user experience to new heights with futuristic terminal.",
    },
    {
      image: "../../../assets/Images/cx20 pro.png",
      CardHeading: "AndPos Cx20",
      // link:"/andpos-cx20",
      content: "Windows Powered ECR Terminal Efficiency Meets Compatibility",
    },
    {
      image: "../../../assets/Images/HS20.png",
      CardHeading: "Andpos HS20",
      // link: "/hs20",
      content: "Designed for long-lasting durability and reliable, HS20 is ideal for demanding environments such as retail, logistics, and hospitality.",
    },
    {
      image: "../../../assets/Images/L20.png",
      CardHeading: "Andpos L20",
      // link: "/l20",
      content: "Even during the busiest hours, L20 operates as a reliable countertop or indoor portable payment terminal.",
    },
    {
      image: "../../../assets/Images/M20 SE.png",
      CardHeading: "Andpos M20 SE",
      // link: "/m20-se",
      content: "Upgrade your experience with a combination of speed, convenience and portability",
    },
    {
      image: "../../../assets/Images/soundbox 1.png",
      CardHeading: "Andpos NS20 Pro",
      // link:"/ns20-pro",
      content:"NS20 pro is a network speaker in the shape of a QR code signage stand, designed with merchant's convenience.",
    },
    {
      image: "../../../assets/Images/soundbox 2.png",
      CardHeading: "Andpos NS20 S",
      // link:"/ns20-s",
      content: "NS20 S is a network speaker in the shape of a QR code signage stand, designed with merchant's convenience.",
    },
    {
      image: "../../../assets/Images/soundbox 3.png",
      CardHeading: "Andpos NS30",
      // link:"/ns30",
      content: "NS30 is a network speaker designed as a lucky cat.",
    },
    {
      image: "../../../assets/Images/p20.png",
      CardHeading: "Andpos P20",
      // link: "/p20",
      content: "Power of Android meets the convenience of a PIN pad",
    },
    {
      image: "../../../assets/Images/p30.png",
      CardHeading: "Andpos P30",
      // link:"/p30",
      content:"Combining sleek aesthetics with superior performance and enhanced security.",
    },
  ];

  return (
    <div
      className="container mx-auto w-full h-full lg:pb-16 lg:my-5"     
    >
      <section className="w-4/5 mx-auto font-[inter]">
        <h3 className="xl:text-[42px] lg:text-[42px]  text-[30px] md:text-[48px] font-semibold block text-center">
          Our Products
        </h3>
        <p className="text-center text-xs md:text-lg">
          Choose the terminal option that works for you
        </p>
        <div className="md:gap-8 sm:gap-12 pt-12 md:pt-14 " > 
          <ProductsCard data={cardData}/>
        </div>
      </section>
    </div>
  );
};

export default OurProducts;
