import React from 'react'

function Clitefeature() {
    return (
        <div className='xl:py-[100px] lg:py-[100px] md:py-[30px] py-[25px] xl:px-0 lg:px-0 md:px-0 px-[20px]'>
            <div className='container mx-auto'>
                <div className='text-center max-w-[700px] mx-auto'>
                    <p className='xl:text-[42px] lg:text-[42px] md:text-[42px] text-[30px] md:text-[48px] font-semibold text-center'>
                        Enriching the viewing experience with enhanced clarity
                    </p>
                    <p className='xl:text-[16px] lg:text-[16px] md:text-[14px] text-black font-normal mt-[25px]'>
                        Immerse yourself in animations and vibrant image displays, all on a screen that guarantees
                        smooth operation and maximum display quality.
                    </p>
                </div>
                <div className='flex xl:flex-row lg:flex-row md:flex-row flex-col items-start justify-between mt-[38px]'>
                    <div className='xl:w-[49%] lg:w-[49%] md:w-[49%] w-[99%] bg-[#F3F2F2] rounded-[8px] px-[25px]'>
                        <div className='max-w-[520px] mx-auto'>
                            <img src='../../../../../assets/Images/Mask group 1.png'></img>
                        </div>
                        <p className='xl:text-[16px] lg:text-[16px] md:text-[14px] text-black font-normal my-[25px] max-w-[300px] pb-[30px] px-[25px] '>
                            With the bright backlit keyboard
                            L20 ensures both portability and an
                            exceptional viewing experience.
                        </p>
                    </div>
                    <div className='xl:w-[49%] lg:w-[49%] md:w-[49%] w-[99%]'>
                        <div className=' bg-[#F3F2F2] rounded-[8px] xl:px-[55px] lg:px-[55px] md:px-[20px] px-[15px] py-[23px] xl:mt-0 lg:mt-0 md:mt-0 mt-[15px]'>
                            <p className='xl:text-[42px] lg:text-[42px]  text-[30px] md:text-[20px] font-semibold text-start max-w-[350px]'>
                                Simplify
                                CableConnections
                            </p>
                            <p className='xl:text-[16px] lg:text-[16px] md:text-[14px] text-black font-normal my-[25px]'>
                                No more struggling to plug in the right way or dealing
                                with tangled cables. L20 Type-C USB s universally compatible
                                with smartphone, tablets and computers,
                                making it easy to connect all your devices.
                            </p>
                        </div>
                        <div className='bg-[#F3F2F2] rounded-[8px] px-[55px] pb-[24px] mt-3'>
                            <div className='max-w-[240px] mx-auto'>
                                <img src='../../../../../assets/Images/Group 95.png'></img>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Clitefeature