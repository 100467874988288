import React from 'react';

const Card = ({ data }) => {
  return (
    <>
      {data && data.map((cardData, index) => (
        <div
          key={index}
          className="group bg-[#FFF6F1] relative font-[inter] xl:p-10 lg:p-10 p-5 rounded-lg mx-auto mt-16 border-4 border-transparent transition duration-300 ease-in-out"
        >
          <div className="absolute xl:-top-14 lg:-top-14 md:-top-14 -top-10 xl:w-24 lg:w-24 md:w-24 w-[70px] xl:h-24 lg:h-24 md:h-24 h-[70px] bg-[#FFE9DD] z-40 border-4 xl:border-[#FFE1D3] lg:border-[#FFE1D3] border-[#f26631] rounded-full flex items-center justify-center transition duration-300 ease-in-out group-hover:border-[#FF5E14]">
            <span className="xl:text-4xl lg:text-4xl md:text-4xl text-2xl text-[rgb(255,94,20)]">
              {cardData.icon}
            </span>
          </div>
          <h2 className="xl:text-xl lg:text-xl md:text-xl text-lg font-semibold mb-4 pt-6 text-center">{cardData.CardHeading}</h2>
          <p className="text-gray-700 xl:text-base lg:text-base	md:text-base text-sm text-center">
            {cardData.content}
          </p>
          <div className="absolute inset-0 border-4 xl:border-transparent lg:border-transparent border-[#f26631] rounded-lg group-hover:border-[#FF5E14] transition duration-300 ease-in-out"></div>
        </div>
      ))}
    </>
  );
}

export default Card;
