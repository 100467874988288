import React, { useEffect, useRef, useState } from 'react';
import Features from './Features';
import Viewresponsive from './Viewresponsive';
import Printondemand from './Printondemand';
import AutoplaySlider from './Slider';

function Bannersection() {
  const [index, setIndex] = useState(0);
  const timeoutRef = useRef(null);
  const colors = ["#0088FE", "#00C49F", "#FFBB28", "#FFBB28", "#FFBB28", "#FFBB28"];
  const delay = 2500;

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }
  

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(() => {
      // Transition to the next slide or reset if we reached the duplicate at the end
      setIndex((prevIndex) => (prevIndex === colors.length ? 1 : prevIndex + 1));
    }, delay);

    return () => {
      resetTimeout();
    };
  }, [index]);

  // Handle the transition to the start of the slide (index 0) after a quick reset
  useEffect(() => {
    if (index === colors.length) {
      // This triggers a reset to the first slide without visible transition
      setTimeout(() => {
        setIndex(0);
      }, 0);
    }
  }, [index, colors.length]);

  return (
    <div>
     
    <AutoplaySlider/>
      <Features />
      <Viewresponsive />
      <Printondemand />
    </div>
  );
}

export default Bannersection;
