import React from 'react'

function KeyFeatures() {
  return (
    <div className='container mx-auto xl:py-[100px] lg:py-[100px] md:py-[30px] py-[25px]  px-[25px]'>
      <div className='flex xl:flex-nowrap xl:gap-0 lg:gap-0 md:gap-0 gap-10 lg:flex-nowrap md:flex-nowrap flex-wrap items-center justify-between'>
        <div className='xl:w-[50%]'>
          <img className='w-[60%] text-center mx-auto' src="../../../../../assets/Images/C20  Lite 1.png"></img>
        </div>
        <div className='w-50% xl:mx--0 lg:mx-0 md:mx-0 mx-[20px]'>
          <p className='xl:text-[42px] lg:text-[42px] md:text-[30px] text-[30px] xl:ml-[-25px] lg:ml-[-25px]'>
            Key features:
          </p>
          <div className=''>
            <ul className='list-outside list-disc	xl:text-[20px] lg:text-[20px] md:text-[20px] text-[16px]'>
              <li className='mb-2'>
                Powered by Android 13
              </li>
              <li className='mb-2'>
                Quad-core CPU, 2.0GHz
              </li>
              <li className='mb-2'>
                Main display: 15.6" at 1920x1080 FHD
              </li>
              <li className='mb-2'>
                Customer display (optional): single screen only, 10.1" at 1280x800 HD
              </li>
            </ul>
          </div>
          <button className='xl:mt-[35px] lg:mt-[35px] md:mt-[25px] mt-[25px] bg-[#FF5E14] rounded-[4px] py-[14px] px-[20px] text-white xl:text-[20px] lg:text-[20px] md:text-[18px] text-[16px] font-semibold'>
            C20 Lite DATASHEET
          </button>
        </div>
      </div>
    </div>
  )
}

export default KeyFeatures