import React from 'react'


function ProductSpecification() {
  return (
    <div className='bg-white'>

      <div className=' xl:py-[100px] lg:py-[100px] md:py-[30px] py-[25px]'>
        <div className='container mx-auto'>
          <p className='text-[#222222] xl:text-[42px] lg:text-[42px] md:text-[30px] text-[30px] font-semibold text-center'>
            Product Specifications
          </p>
          <div className='xl:grid-cols-[auto_auto_auto_auto] lg:grid-cols-[auto_auto_auto_auto] md:grid-cols-[auto_auto_auto_auto] grid-cols-[auto_auto] grid gap-y-[60px] mt-[50px] xl:mx-[60px] lg:mx-[100px]'>
            <div className='max-w-[150px] mx-auto '>
              <div className='bg-[#F2F6F8] h-[88px] w-[84px] rounded-[50%] flex items-center justify-center'>
                <img src="../../../../../assets/Images/Group.png"></img>
              </div>
              <div className='max-w-[150px] mx-auto  '>
                <p className='text-[#222222] text-[16px] font-bold mt-4'>
                  Processor
                </p>
                <p className='text-[16px] font-normal mt-2'>
                  Qualcomm Quad-
                  core, 2.0GHz
                </p>
              </div>
            </div>
            <div className='max-w-[150px] mx-auto'>
              <div className='bg-[#F2F6F8] h-[88px] w-[84px] rounded-[50%] flex items-center justify-center'>
                <img src="../../../../../assets/Images/memory-card-svgrepo-com 1.png"></img>
              </div>
              <div className='max-w-[150px] mx-auto '>
                <p className='text-[#222222] text-[16px] font-bold mt-4'>
                  Memory
                </p>
                <p className='text-[16px] font-normal mt-2'>
                  Qualcomm Quad-
                  core, 2.0GHz
                </p>
              </div>
            </div>
            <div className='max-w-[150px] mx-auto'>
              <div className='bg-[#F2F6F8] h-[88px] w-[84px] rounded-[50%] flex items-center justify-center'>
                <img src= "../../../../../assets/Images/Group 73.png"></img>
              </div>
              <div className='max-w-[150px] mx-auto '>
                <p className='text-[#222222] text-[16px] font-bold mt-4'>
                  Display Touchscreen
                </p>
                <p className='text-[16px] font-normal mt-2'>
                  Qualcomm Quad-
                  core, 2.0GHz
                </p>
              </div>
            </div>
            <div className='max-w-[150px] mx-auto'>
              <div className='bg-[#F2F6F8] h-[88px] w-[84px] rounded-[50%] flex items-center justify-center'>
                <img src="../../../../../assets/Images/aria-braille-svgrepo-com 1.png"></img>
              </div>
              <div className='max-w-[150px] mx-auto '>
                <p className='text-[#222222] text-[16px] font-bold mt-4'>
                  OS
                </p>
                <p className='text-[16px] font-normal mt-2'>
                  Qualcomm Quad-
                  core, 2.0GHz
                </p>
              </div>
            </div>
            <div className='max-w-[150px] mx-auto '>
              <div className='bg-[#F2F6F8] h-[88px] w-[84px] rounded-[50%] flex items-center justify-center'>
                <img src="../../../../../assets/Images/scanner-svgrepo-com.png"></img>
              </div>
              <div className='max-w-[150px] mx-auto  '>
                <p className='text-[#222222] text-[16px] font-bold mt-4'>
                  Scanner
                </p>
                <p className='text-[16px] font-normal mt-2'>
                  Qualcomm Quad-
                  core, 2.0GHz
                </p>
              </div>
            </div>
            <div className='max-w-[150px] mx-auto '>
              <div className='bg-[#F2F6F8] h-[88px] w-[84px] rounded-[50%] flex items-center justify-center'>
                <img src="../../../../../assets/Images/keypad-svgrepo-com.png"></img>
              </div>
              <div className='max-w-[150px] mx-auto '>
                <p className='text-[#222222] text-[16px] font-bold mt-4'>
                  Keypad
                </p>
                <p className='text-[16px] font-normal mt-2'>
                  Qualcomm Quad-
                  core, 2.0GHz
                </p>
              </div>
            </div>
            <div className='max-w-[150px] mx-auto '>
              <div className='bg-[#F2F6F8] h-[88px] w-[84px] rounded-[50%] flex items-center justify-center'>
                <img src="../../../../../assets/Images/audio-spectrum-analyzer-svgrepo-com.png"></img>
              </div>
              <div className='max-w-[150px] mx-auto '>
                <p className='text-[#222222] text-[16px] font-bold mt-4'>
                  Audio
                </p>
                <p className='text-[16px] font-normal mt-2'>
                  Qualcomm Quad-
                  core, 2.0GHz
                </p>
              </div>
            </div>
            <div className='max-w-[150px] mx-auto '>
              <div className='bg-[#F2F6F8] h-[88px] w-[84px] rounded-[50%] flex items-center justify-center'>
                <img src="../../../../../assets/Images/Group 74.png"></img>
              </div>
              <div className='max-w-[150px] mx-auto '>
                <p className='text-[#222222] text-[16px] font-bold mt-4'>
                  Connectivity
                </p>
                <p className='text-[16px] font-normal mt-2'>
                  Qualcomm Quad-
                  core, 2.0GHz
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductSpecification