import React from 'react'

function Unshakeable() {
    return (
        <div className='display-bg-img xl:px-0 lg:px-0 md:px-[20px] px-[20px] xl:py-0 lg:py-0 md:py-[20px] py-[20px]'>
            <div className='container mx-auto'>
                <div className='flex xl:flex-row lg:flex-row md: flex-row flex-col items-center justify-center'>
                    <div className='xl:w-[50%] lg:w-[50%] md:w-[50%] w-[100%] xl:order-none	 lg:order-none	 md:order-none	 order-1'>
                        <p className='text-[#222222] xl:text-[42px] lg:text-[42px] md:text-[30px] text-[30px] font-semibold text-white'>
                            Unshakeable support
                        </p>
                        <p className='text-[16px] font-normal text-white lg:max-w-[250px] xl:mt-[50px] lg:mt-[50px] md:mt-[50px] mt-[25px]'>
                            elevate your device with our innovative triangle stand design The triangular base offers a steadyand well-balanced foundation,ensuring stability during transactions.
                        </p>
                    </div>
                    <div className=''>
                        <img className='xl:w-[100%] lg:w-[100%] md:w-[50%] w-[50%] mx-auto' src="../../../../../assets/Images/display.png"></img>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Unshakeable