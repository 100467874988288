import React from 'react'
function AdvanceConnectivity() {
  return (
    <div>
      <div className='bg-[#F3755B] xl:py-[60px] lg:py-[60px] md:py-[30px] py-[25px] xl:px-[0px] lg:px-[0px] md:px-[0px] px-[13px]'>
        <div className='container mx-auto '>
          <p className='xl:text-[42px] lg:text-[42px] md:text-[30px] text-[30px] text-white text-center font-semibold'>
            Advance Connectivity
          </p>
          <p className='font-seminold text-[16px] text-white text-center mt-3'>
            Multi methods to make you always connected.
          </p>
          <div className='mt-[40px] flex xl:gap-0 lg:gap-0 md:gap-0 gap-[25px] items-center xl:justify-between lg:justify-between md:justify-between justify-center Advance Connectivity max-w-[940px] mx-auto xl:flex-nowrap lg:flex-nowrap	md:flex-nowrap flex-wrap xl:p-0 lg:p-0 md:px-[15px]'>
            <div className='xl:w-[100px] lg:w-[100px] md:w-[100px] w-[80px] h-[100px] '>
              <img src="../../../../../assets/Images/smartFull-icon3.png"></img>
            </div>
            <div className='xl:w-[100px] lg:w-[100px] md:w-[100px] w-[80px] h-[100px]'>
              <img src="../../../../../assets/Images/smartFull-icon2.png"></img>
            </div>
            <div className='xl:w-[100px] lg:w-[100px] md:w-[100px] w-[80px] h-[100px]'>
              <img src="../../../../../assets/Images/smartFull-icon1.png"></img>
            </div>
            <div className='xl:w-[100px] lg:w-[100px] md:w-[100px] w-[80px] h-[100px]'>
              <img src="../../../../../assets/Images/20220914174408_88709 (1).png"></img>
            </div>
            <div className='xl:w-[100px] lg:w-[100px] md:w-[100px] w-[80px] h-[100px]'>
              <img src="../../../../../assets/Images/20220914174420_99048.png"></img>
            </div>
            <div className='xl:w-[100px] lg:w-[100px] md:w-[100px] w-[80px] h-[100px]'>
              <img src="../../../../../assets/Images/20220914174414_29588.png"></img>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdvanceConnectivity