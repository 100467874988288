import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { styled } from '@mui/material/styles';
import SwipeableViews from 'react-swipeable-views';
import { Link } from 'react-router-dom';

const StyledTab = styled(Tab)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: '600',
  color: '#717171',
  '&.Mui-selected': {
    color: '#FF5E14',
  },
}));
const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    top: 3,
    bottom: 3,
    right: 3,
    height: 'auto',
    background: 'none',
    zIndex: 0,
  },
  '& .MuiTabs-indicatorSpan': {
    position: 'absolute',
    top: 7,
    left: 0,
    right: 0,
    bottom: 7,
    borderBottom: '1px solid #F3755B',
  },
});

const tabData = [
  {
    label: 'Checkout',
    products: [
      {
        name: 'C2 Light',
        description: 'Android ECR',
        image: './assets/c20 pro 1 2.png'
      },
      {
        name: 'C2 Light Plus',
        description: 'Android ECR',
        image: './assets/c20 pro 1 2.png'
      },
    ],
  },
  {
    label: 'Mobility',
    products: [
      {
        name: 'Mobi Light',
        description: 'Portable Android Device',
        image: './assets/mobi light.png'
      },
      {
        name: 'Mobi Pro',
        description: 'Advanced Android Device',
        image: './assets/mobi pro.png'
      },
    ],
  },
  {
    label: 'Payment',
    products: [
      {
        name: 'PayFast',
        description: 'Secure Payment Terminal',
        image: './assets/payfast.png'
      },
      {
        name: 'PaySecure',
        description: 'High-Security Terminal',
        image: './assets/paysecure.png'
      },
    ],
  },
  {
    label: 'Accessories',
    products: [
      {
        name: 'Case Pro',
        description: 'Durable Device Case',
        image: './assets/case pro.png'
      },
      {
        name: 'Charger Max',
        description: 'Fast Charging Adapter',
        image: './assets/charger max.png'
      },
    ],
  },
];

function Modals({ onMouseEnter }) {
  const [tabIndex, setTabIndex] = React.useState(0);
  const handleMouseLeave = () => {
    onMouseEnter(false);
  };
  return (
    <div className="app bg-[#E7F8FE] py-[20px]" onMouseLeave={handleMouseLeave}>
      <div className="tab-box container mx-auto">
        <StyledTabs
          value={tabIndex}
          onChange={(e, index) => setTabIndex(index)}
          variant="scrollable"
          scrollButtons={false}
          centered
        >
          {tabData.map((tab, index) => (
            <StyledTab key={index} disableRipple label={tab.label} />
          ))}
        </StyledTabs>
      </div>
      <div className="container mx-auto">
        <SwipeableViews
          index={tabIndex}
          enableMouseEvents
          resistance
          onChangeIndex={(index) => setTabIndex(index)}
        >
          {tabData.map((tab, tabIndex) => (
            <div className="page" key={tabIndex}>
              <div className="flex gap-5">
                {tab.products.map((product, productIndex) => (
                  <div className="w-[160px] mt-[30px]" key={productIndex}>
                    <Link to="/ecr" onClick={() => onMouseEnter(false)}>
                      <div className="w-[160px] h-[160px] bg-[#FCF7F7] rounded-[8px] flex items-center justify-center hover:[box-shadow:1px_1px_#d1d1d1]  hover:scale-[1.1] hover:z-0 hover:duration-[1000ms]">
                        <img src={product.image} alt={product.name} className="h-[100px] w-[100px]" />
                      </div>
                    </Link>
                    <p className="text-base font-semibold text-center mt-[25px]">
                      {product.name}
                    </p>
                    <p className="text-base font-normal text-center mt-[10px]">
                      {product.description}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </SwipeableViews>
      </div>
    </div>
  );
}

export default Modals;
