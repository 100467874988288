import React from "react";
import dot from "../../assets/andpos/dot.png";

import { CiInstagram, CiLinkedin, CiLocationOn } from "react-icons/ci";
import { RiFacebookBoxLine, RiTwitterXFill } from "react-icons/ri";
const Footer = ({ productRef }) => {
  const handleProductScroll = () => {
    productRef.current.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <section
      className="w-full h-full bg-[#F3755B] mt-5 py-2 sm:py-5 md:py-10"
      id="help"
    >
      <div className="flex flex-col md:flex-row justify-start md:items-center gap-5 sm:gap-8 md:gap-14 lg:gap-20">
        <div className="bg-white md:w-[60%] lg:w-[50%] h-auto rounded-e-full p-2 sm:p-4 md:p-10 lg:p-14 space-y-5 xl:mr-0 lg:mr-0 mr-[15px]">
          <img
            src="../../../../assets/Images/andpos-logo.svg"
            alt="ANDPOS Logo"
            className="xl:w-[20%] lg:w-[20%] w-[50%]"
          />
          <p className="xl:text-sm lg:text-sm md:text-sm text-xs">
            AndPOS machines integrate effortlessly with compatible ERP
            solutions, providing businesses with comprehensive products to
            manage inventory, sales, and customer data efficiently and deal with
            payment devices as a hardware product.
          </p>
          {/* <div className="space-y-3">
            <div className="flex items-center gap-2">
              <CiLocationOn className="text-[#F3755B]" size={20} />
              <span className="text-sm font-light text-[#F3755B]">
                Jaipur Rajasthan India 302021
              </span>
            </div>
            <div className="flex items-center gap-2">
              <CiInstagram className="text-[#F3755B]" size={20} />
              <CiLinkedin className="text-[#F3755B]" size={20} />
              <RiTwitterXFill className="text-[#F3755B]" size={20} />
              <RiFacebookBoxLine className="text-[#F3755B]" size={20} />
            </div>
          </div> */}
        </div>
        <div>
          <div className="text-white flex justify-evenly md:justify-between px-12 gap-10 md:gap-14 lg:gap-40">
            <div>
              <h3 className="border-b border-[#4DC8F4] py-2 font-semibold text-lg">
                Services
              </h3>
              <ul className="text-sm font-light space-y-2 mt-2 cursor-pointer">
                <li onClick={handleProductScroll}>POS Consultation</li>
                <li onClick={handleProductScroll}>Technical Support</li>
                <li onClick={handleProductScroll}>Retail POS</li>
              </ul>
            </div>
            <div>
              <h3 className="border-b border-[#4DC8F4] py-2 font-semibold text-lg">
                Quick Link
              </h3>
              <ul className="text-sm font-light space-y-2 mt-2 cursor-pointer">
                <li>Home</li>
                <li>Product</li>
                <li>About</li>
                <li>Contact Us</li>
                <li>Help</li>
              </ul>
            </div>
          </div>
          <div className="text-white flex justify-evenly  md:justify-start gap-10 md:gap-14 lg:gap-20 mt-5 md:mt-10 lg:mt-20">
            <div>
              {/* <div className="text-sm">Mobile: +91 8985XXXX</div>
                <div className="text-sm">Gmail: andpos20@andpos.com</div> */}
            </div>
            <div>{/* <img src={dot} alt="dot" className="w-24 h-16" /> */}</div>
          </div>
        </div>
      </div>
      <div className="w-[90%] h-[1px] mx-auto bg-white mt-5 md:mt-10" />
      <div className="text-center font-semibold text-white mt-5">
        Copyright ©2024 Andpos All rights reserved.
      </div>
    </section>
  );
};

export default Footer;
