import React from "react";

const Larger = () => {
  return (
    <div className="px-8 lg:px-40 py-12">
      <div>
        <h4 className="text-3xl font-bold">Larger and vivid display</h4>

        <p className="text-sm font-normal pt-4 leading-5">
          Enjoy the luxury of a spacious 15.6-inch touch screen that not only
          elevates your productivity but also provides an intuitive interface
          for seamless control. Our touchscreen technology redefines
          convenience,making every interaction a breeze and delivers stunning
          visuals with vibrant colors on a high-quality display.
        </p>

        <div className="pt-12">
          <img src="/banner/larger.png" />
        </div>
      </div>
    </div>
  );
};

export default Larger;
