import React from 'react'

function Features() {
  return (
    <div className='Feature-bg-img xl:py-[100px] lg:py-[100px] md:py-[50px] py-[30px] xl:px-0 lg:px-0 md:px-[20px] px-[20px]'>
      <div className='container mx-auto'>
        <div className='xl:flex lg:flex block  items-center'>
          <div className='xl:w-[50%] lg:w-[50%] md:w-[80%] w-[100%] xl:mx-0 lg:mx-0 mx-auto'>
            <div className='xl:max-w-[380px] lg:max-w-[380px] mx-auto'>
              <p className='text-[16px] text-white'>
                The CX20 is powered by a Hexa-core intel
                <strong> i3-1215U</strong> processor that can reach up to
                4.4GHz, compatible with <strong>Windows 11 loT </strong>
                it delivers outstanding performance for
                high-demand workloads and multitasking.
              </p>
              <div className='mt-[50px] flex items-center justify-between'>
                <div className='border-b border-[#ffffff] py-[10px]'>
                  <p className='text-white text-[16px] font-bold'>Intel Core</p>
                  <p className='text-white text-[16px] font-normal'>I31215U</p>
                </div>
                <div className='border-b border-[#ffffff] py-[10px] min-w-[100px]'>
                  <p className='text-white text-[16px] font-bold'>Windows</p>
                  <p className='text-white text-[16px] font-normal'>System</p>
                </div>
              </div>
              <div className='mt-[20px] flex items-center justify-between'>
                <div className='border-b border-[#ffffff] py-[10px]'>
                  <p className='text-white text-[16px] font-bold'>Up To 4.4 GHz</p>
                  <p className='text-white text-[16px] font-normal'>CPU Clock Speed</p>
                </div>
                <div className='border-b border-[#ffffff] py-[10px] min-w-[100px]'>
                  <p className='text-white text-[16px] font-bold'>8GB +256 GB</p>
                  <p className='text-white text-[16px] font-normal'>User Memory</p>
                </div>
              </div>
            </div>
          </div>
          <div className='xl:w-full lg:w-full max-w-[50%] mx-auto'>
            <img className='xl:w-full lg:w-full max-w-[70%]' src='../../../../../assets/Images/c20 pro 1 4 (2).png'></img>
          </div>

        </div>
      </div>

    </div>
  )
}

export default Features