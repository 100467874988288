import React from 'react'

function Versatile() {
    return (
        <div className='Versatile-bg xl:py-[100px] lg:py-[100px] md:py-[50px] py-[30px] xl:px-0 lg:px-0 md:px-[20px] px-[20px]'>
            <div className='container mx-auto'>
                <div className='flex xl:flex-row lg:flex-row md:flex-row flex-col items-center'>
                   <div className='xl:w-[50%] lg:w-[50%] md:w-[50%] w-[100%]'>
                   <div className='xl:max-w-[450px] lg:max-w-[450px]'>
                        <p className='xl:text-[42px] lg:text-[42px] md:text-[42px] text-[30px] md:text-[48px] font-semibold text-white'>
                            Versatile, secure and efficient
                        </p>
                      <div className='xl:mt-[50px] lg:mt-[50px] mt-[20px]'>
                      <p className='xl:text-[16px] lg:text-[16px] md:text-[14px] text-white mb-1 font-normal'>
                            Even during the busiest hours,
                        </p>
                        <p className='xl:text-[16px] lg:text-[16px] md:text-[14px] text-white mb-1 font-normal'>
                            L20 operates as a reliable countertop or indoor portable payment terminal,
                        </p>
                        <p className='xl:text-[16px] lg:text-[16px] md:text-[14px]  text-white mb-1 font-normal'>
                            delivering peak performance. It ensures secure and fast payment processing,
                        </p>
                        <p className='xl:text-[16px] lg:text-[16px] md:text-[14px text-white mb-1 font-normal'>
                            offering both merchants and customers a secure and efficient payment experience.
                        </p>
                      </div>
                    </div>
                   </div>
                   <div className='xl:w-[50%] lg:w-[50%] md:w-[50%] w-[100%]'>
                   <img className='xl:w-[50%] lg:w-[50%] w-[50%] mx-auto xl:mt-0 lg:mt-0 md:mt-0 mt-[15px]' src='../../../../../assets/Images/framee.png'></img>
                   </div>
                </div>
            </div>
        </div>
    )
}

export default Versatile