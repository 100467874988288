import React from "react";
import { GrDocumentText } from "react-icons/gr";
import Card from "../common/Card";

const ProductFeatures = () => {
  const cardData = [
    {
      icon: <GrDocumentText />,
      CardHeading: "Enhanced Security Features:",
      content:"Each POS machine has advanced security protocols to safeguard transactions and sensitive data. From encrypted payment processing to secure cloud connectivity, we prioritize your business's security needs.",
    },
    {
      icon: <GrDocumentText />,
      CardHeading: "Scalable Solutions:",
      content:"Our services are designed to grow your business. Whether using the AndPos POS machines  for a small setup or for a large-scale operation, we provide scalable configurations that adapt to the evolving requirements.",
    },
    {
      icon: <GrDocumentText />,
      CardHeading: "Regular Maintenance and Updates:",
      content:"We offer regular maintenance checks and software updates to keep your POS systems running efficiently. Our products ensure optimal performance, minimizes downtime, and extends the life of investment. ",
    },
    {
      icon: <GrDocumentText />,
      CardHeading: "Energy-Efficient Configurations:",
      content:"Models like the AndPos C20 Lite, P30, NS20 pro, and more are optimized for energy efficiency, reducing operational costs while maintaining high performance, perfect for budget-conscious businesses. ",
    },
    {
      icon: <GrDocumentText />,
      CardHeading: "Real-Time Monitoring and Analytics:",
      content:"Leverage real-time analytics to monitor sales, inventory, and performance metrics across your POS systems. This feature empowers data-driven decision-making to boost profitability and efficiency.",
    },
    {
      icon: <GrDocumentText />,
      CardHeading: "Premium Customer Support:",
      content:"Our dedicated customer support team is available 24/7 to assist, from troubleshooting to advanced technical support, ensuring your business runs smoothly without interruptions.",
    },
  ];

  return (
    <>
     <div className="container mx-auto   w-full h-full  lg:py-16 lg:my-5">
       <section className=" wow bounceInUp w-full px-4 md:px-0 md:w-4/5 mx-auto font-[inter] xl:py-0 lg:py-0 md:py-0 py-4">
        <h3 className="xl:text-[42px] lg:text-[42px] text-[30px] md:text-[48px] font-semibold text-center">
          Product Features
        </h3>
        <p className="text-center text-xs md:text-lg mt-4 font-thin text-gray-500 ">
          Point of Sale (POS) systems are versatile and can offer a variety of
          services depending on the business type and industry.
          <br className="hidden md:block" />
          Here are some common types of POS services:
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 md:gap-14 xl:pt-12 lg:pt-12 pt-0 md:pt-12">
          <Card data={cardData} />
        </div>
      </section>   
     </div>
    
    </>
  );
};

export default ProductFeatures;
