import React, { useState } from "react";
import { FiAlignRight } from "react-icons/fi";
import { Link } from "react-router-dom";
import Modals from "../modal/Modals";

const Header = ({ isOpen }) => {
  const [hamberMenu, setHamburgerMenu] = useState(false);
  const [productmodal, setProductModal] = useState(false);

  const handleMouseEnter = () => setProductModal(true);
  const handleMouseLeave = () => setProductModal(false);

 

  return (
    <>
      <header
        className="sticky top-0 z-50 shadow-sl bg-white text-[#222222]"
        style={{
          boxShadow:
            "0 14px 28px rgba(0,0,0,0.05), 0 10px 10px rgba(0,0,0,0.05)",
        }}
      >
        <div className="container mx-auto flex justify-between items-center p-1">
          <div className="text-xl font-bold">
            <Link to="/">
              <img
                className="xl:w-full lg:w-full"
                src="../../../../assets/Images/andpos-logo.svg"
                alt="Logo"
              />
            </Link>
          </div>
          <nav className="hidden md:flex xl:gap-[53px] lg:gap-[53px] gap-[25px] mr-16">
            <Link to="/">
              <p className="text-slate-950 text-xl font-semibold">Home</p>
            </Link>
            <a
              href="/#about"
              className="text-slate-950 text-xl font-semibold"
            >
              {" "}
              About{" "}
            </a>
            <a
              href="/#product"
              className="text-slate-950 font-semibold text-xl cursor-pointer"
            >
              Product
            </a>
            <a href="/#help" className="text-slate-950 text-xl font-semibold">
              Help
            </a>
            <Link
              to="/contact"
              className="text-slate-950 text-xl font-semibold"
              onClick={() => isOpen(false)}
            >
              Contact Us
            </Link>
          </nav>
          {/* <Link to="/contact">
             <button
              className="hidden md:block rounded-md bg-[#FF5E14] text-white text-base sm:text-lg lg:text-xl py-1 sm:py-[7px] px-3 sm:px-[15px]"
              onClick={() => isOpen(true)}
            >
              Get In Touch
            </button> 
          </Link>  */}
          <div className="md:hidden">
            <button
              className="text-white bg-gray-800 focus:outline-none p-1 rounded"
              onClick={() => setHamburgerMenu(!hamberMenu)}
            >
              <FiAlignRight size={20} />
            </button>
          </div>
        </div>
        {hamberMenu && (
          <div className="md:hidden">
            <div className="text-xl font-bold">
              <Link to="/">
                <img
                  className="xl:w-full lg:w-full"
                  src="../../../../assets/Images/andpos-logo.svg"
                  alt="Logo"
                />
              </Link>
            </div>
            <nav className="flex flex-col items-center space-y-2 p-4">
              <a href="/" className="hover:text-gray-400">
                Home
              </a>
              <a href="/#product" className="hover:text-gray-400">
                Product
              </a>
              <a href="#about" className="hover:text-gray-400">
                About
              </a>
              <a href="/contact" className="hover:text-gray-400">
                Contact Us
              </a>
              <a href="/#help" className="hover:text-gray-400">
                Help
              </a>
            </nav>
          </div>
        )}
      </header>
      {/* {productmodal && <Modals onMouseEnter={setProductModal} onMouseLeave={handleMouseLeave} />} */}
    </>
  );
};

export default Header;
