import React from "react";
import Banner from "../../Component/Ns30/Banner";
import KeyFeatures from "../../Component/Ns30/KeyFeatures";
import EveryPayment from "../../Component/Ns30/EveryPayment";
import Larger from "../../Component/Ns30/Larger";
import VersatilePorts from "../../Component/Ns30/VersatilePorts";
import Specification from "../../Component/Ns30/Specification";
// import FooterNew from "../../Component/FooterNew";

const Ns30 = () => {
  return (
    <div>
      <div>
        <Banner />
      </div>
      <div>
        <KeyFeatures />
      </div>

      <div>
        <EveryPayment />
      </div>
      <div>
        <Larger />
      </div>

      <VersatilePorts />

      <div>
        <Specification />
      </div>

      {/* <FooterNew /> */}
    </div>
  );
};

export default Ns30;
