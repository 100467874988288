import React from 'react'

function Productdescription() {
  return (
    <div className='bg-white  xl:py-[100px] lg:py-[100px] md:py-[50px] py-[30px] xl:px-0 lg:px-0 md:px-[20px] px-[20px]'>
      <div className='container mx-auto'>
        <p className='xl:text-[42px] lg:text-[42px] md:text-[30px] text-[30px] font-semibold'>
          Spacious touchscreen interface
        </p>
        <p className='text-[16px] font-normal xl:my-[50px] lg:my-[50px] md:my-[30px] mt-[20px] xl:max-w-[560px] lg:max-w-[560px] md:max-w-[560px]'>
          Enjoy a spacious and responsive touchscreen interface,
          providing ample room for easy navigation and enhanced user interaction.
        </p>
        <div>
          <img className='w-[100%]' src="../../../../../assets/Images/new-bg.png"></img>
        </div>
      </div>
    </div>
  )
}

export default Productdescription