import React from "react";
import Banner from "../../Component/AndposCx20/Banner";
import KeyFeatures from "../../Component/AndposCx20/KeyFeatures";
import EveryPayment from "../../Component/AndposCx20/EveryPayment";
import Larger from "../../Component/AndposCx20/Larger";
import VersatilePorts from "../../Component/AndposCx20/VersatilePorts";
import Specification from "../../Component/AndposCx20/Specification";
// import FooterNew from "../../Component/FooterNew";

const AndposCx20 = () => {
  return (
    <div>
      <div>
        <Banner />
      </div>
      <div>
        <KeyFeatures />
      </div>

      <div>
        <EveryPayment />
      </div>
      <div>
        <Larger />
      </div>

      <VersatilePorts />

      <div>
        <Specification />
      </div>

      {/* <FooterNew /> */} 
    </div>
  );
};

export default AndposCx20;
