import React from "react";
import Banner from "../../Component/AndposC20Lite/Banner";
import KeyFeatures from "../../Component/AndposC20Lite/KeyFeatures";
import EveryPayment from "../../Component/AndposC20Lite/EveryPayment";
import Larger from "../../Component/AndposC20Lite/Larger";
import VersatilePorts from "../../Component/AndposC20Lite/VersatilePorts";
import Specification from "../../Component/AndposC20Lite/Specification";
// import FooterNew from "../../Component/FooterNew";

const AndposC20Lite = () => {
  return (
    <div>
      <div>
        <Banner />
      </div>
      <div>
        <KeyFeatures />
      </div>

      <div>
        <EveryPayment />
      </div>
      <div>
        <Larger />
      </div>
      <VersatilePorts />
      <div>
        <Specification />
      </div>

      {/* <FooterNew /> */}
    </div>
  );
};

export default AndposC20Lite;
