import React from 'react'

function Display() {                                                         
    return (
        <div className='relative'>
            <div className='display-bg-img py-[100px] xl:mb-[150px] lg:mb-[150px] h-[80vh]'>
                <div className='container mx-auto'>
                    <p className='xl:text-[42px] lg:text-[42px] md:text-[30px] text-[30px] font-semibold text-white'>
                        Larger and Vivid Display
                    </p>
                    <p className='text-[16px] font-normal text-white max-w-[1070px] xl:mt-[50px] lg:mt-[50px] md:mt-[50px] mt-[25px]'>
                        Enjoy the luxury of a spacious 15.6-inch touch screen that not only elevates your productivity but also provides an intuitive interface for seamless control. Our touchscreen technology redefines convenience,making every interaction a breeze and delivers stunning visuals with vibrant colors on a high-quality display.
                    </p>
                </div>
            </div>
            <div className='pb-[100px]'>
                <img className='absolute bottom-[85px] right-0 left-0 mx-auto' src="../../../../../assets/Images/C20  Lite 1.png"></img>
            </div>
        </div>
    )
}
export default Display