import React from 'react'

function Viewresponsive() {
    return (
        <div className='bg-white-img'>
            <div className='container mx-auto xl:py-[100px] lg:py-[100px] md:py-[50px] py-[30px] xl:px-0 lg:px-0 md:px-[20px] px-[20px]'>
                <div className='xl:flex lg:flex md:flex block items-center justify-between'>
                    <div className='xl:w-[50%] lg:w-[50%] md:w-[50%] w-[100%]'>
                        <div className='max-w-[500px] '>
                            <p className='xl:text-[42px] lg:text-[42px] md:text-[30px] text-[30px] font-semibold max-w-[493px]'>
                                Expansive View
                                Responsive Touch
                            </p>
                            <p className='xl:mt-[50px] lg:mt-[50px] mt-[20px]'>
                                Boasts a 15.6" full HD IPS display with a resolution
                                of 1920X1080 pixels for crystal-clear visual
                                complimented by a capacitive multi-touch screen for an intuitive
                                user experience.
                            </p>
                        </div>
                    </div>
                    <div>
                        <img className='xl:w-full lg:w-full md:w-[50%] w-[50%]  mx-auto' src="../../../../../assets/Images/C20  Lite 1 (1).png"></img>
                    </div>
                </div>
            </div>
            <div className='container mx-auto xl:py-[100px] lg:py-[100px] md:py-[50px] py-[30px] xl:px-0 lg:px-0 md:px-[20px] px-[20px]'>
                <div className='xl:flex-row lg:flex-row md:flex flex-col items-center justify-between'>
                    <div className='xl:w-[50%] lg:w-[50%] md:w-[50%] w-[100%]'>
                        <img className='xl:w-full lg:w-full md:w-[50%] w-[50%]  mx-auto' src="../../../../../assets/Images/c20 SE 2 1.png"></img>
                    </div>
                    <div>
                        <div className='max-w-[500px] '>
                            <p className='xl:text-[42px] lg:text-[42px] md:text-[30px] text-[30px] font-semibold max-w-[493px]'>
                                Expansive View
                                Responsive Touch
                            </p>
                            <p className='xl:mt-[50px] lg:mt-[50px] mt-[20px]'>
                                Boasts a 15.6" full HD IPS display with a resolution
                                of 1920X1080 pixels for crystal-clear visual
                                complimented by a capacitive multi-touch screen for an intuitive
                                user experience.
                            </p>
                        </div> </div>
                </div>
            </div>
        </div>
    )
}

export default Viewresponsive