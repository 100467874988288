import React from 'react'

function Moderndesign() {
    return (
        <div className='xl:py-[100px] lg:py-[100px] md:py-[30px] py-[25px] xl:px-0 lg:px-0  px-[20px]'>
            <div className='container mx-auto bg-white'>
                <div className='flex xl:flex-row lg:flex-row md:flex-row flex-col items-start'>
                    <div className='xl:w-[50%] lg:w-[50%] md:w-[50%] w-[100%]'>
                        <div className='max-w-[400px]'>
                            <p className='xl:text-[42px] lg:text-[42px] md:text-[42px] text-[30px] md:text-[48px] font-semibold text-start'>
                                Sleek And Modern Design
                            </p>
                            <p className='xl:text-[16px] lg:text-[16px] md:text-[14px] text-black font-normal mt-[25px]'>
                                Impress customers with a sleek and
                                modern design that not only looks
                                professional but also reflects the
                                cytting-edge Technology Wurgun The POS terminal.
                            </p>
                        </div>
                    </div>
                    <div className='xl:w-[50%] lg:w-[50%] md:w-[50%] w-[100%]'>
                        <div>
                            <img className=' xl:mx-0 lg:mx-0 mx-auto w-[60%] xl:mt-0 lg:mt-0 md:mt-0 mt-[30px]' src="../../../../../assets/Images/Group 90.png"></img>
                        </div>
                    </div>
                </div>
                <div className='bg-[#747477] max-w-[717px] h-[1px] mx-auto mt-[50px]'>

                </div>
            </div>
        </div>
    )
}

export default Moderndesign