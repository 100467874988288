import React from "react";
import Banner from "../../Component/Banner";
import KeyFeatures from "../../Component/KeyFeatures";
import EveryPayment from "../../Component/EveryPayment";
import Larger from "../../Component/Larger";
import VersatilePorts from "../../Component/VersatilePorts";
import Specification from "../../Component/Specification";
import FooterNew from "../../Component/FooterNew";

const Home2 = () => {
  return (
    <div>
      <div>
        <Banner />
      </div>
      <div>
        <KeyFeatures />
      </div>

      <div>
        <EveryPayment />
      </div>
      <div>
        <Larger />
      </div>

      <VersatilePorts />

      <div>
        <Specification />
      </div>

      {/* <FooterNew /> */}
    </div>
  );
};

export default Home2;
