import React from "react";

const EveryPayment = () => {
  return (
    <div>
      {/* <div className="relative">
        <img src="/banner/every_payment.webp" alt="" />

        <div className="absolute top-40 left-16">
            <div className="max-w-xl">
          <h4 className="">Every payment received can be heard loud and clear</h4>

          <p>
            The built-in speaker delivers clear audio for payment notifications
            and alerts, making it the perfect choice for bustling restaurants,
            busy cafes, and any other loud and chaotic settings.
          </p>
          </div>
        </div>
      </div> */}

      <div className="hidden lg:block relative">
           <center> <h4 className="text-2xl font-bold" >
              Spacious touchscreen interface
            </h4><br/>  
            <p>Enjoy a spacious and responsive touchscreen interface,
            providing ample room for easy navigation and enhanced user interaction.</p>          
            </center>         
            
        <img src="/banner/AndposC20Se/every_payment.webp" alt="" />

        {/* <div className="absolute inset-0 flex left-40 top-52">
          <div className="max-w-xl">
            <h4 className="text-2xl font-bold mb-4 max-w-64">
              Every payment received can be heard loud and clear
            </h4>
            <p className="text-lg">
              The built-in speaker delivers clear audio for payment
              notifications and alerts, making it the perfect choice for
              bustling restaurants, busy cafes, and any other loud and chaotic
              settings.
            </p>
          </div>
        </div> */}
      </div>

      <div className=" block lg:hidden bg-[#D8DCDF]">
        <div className="flex justify-center">
          <div>
          <div>
            <img src="/banner/every_payment.png" alt="" />
          </div>

          <div className="px-8 pb-28">
            <h4 className="font-bold text-3xl pt-8">Every payment received can be heard loud and clear.</h4>

            <p className="text-md pt-4">
              The built-in speaker delivers clear audio for payment
              notifications and alerts, making it the perfect choice for
              bustling restaurants, busy cafes, and any other loud and chaotic
              settings.
            </p>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EveryPayment;
