import React from "react";
import Banner from "../../Component/AndposCx20Windows/Banner";
import KeyFeatures from "../../Component/AndposCx20Windows/KeyFeatures";
import EveryPayment from "../../Component/AndposCx20Windows/EveryPayment";
import Larger from "../../Component/AndposCx20Windows/Larger";
import VersatilePorts from "../../Component/AndposCx20Windows/VersatilePorts";
import Specification from "../../Component/AndposCx20Windows/Specification";
// import FooterNew from "../../Component/FooterNew";

const AndposCx20Windows = () => {
  return (
    <div>
      <div>
        <Banner />
      </div>

      <div>
        <KeyFeatures />
      </div>

      <div>
        <EveryPayment />
      </div>
      <div>
        <Larger />
      </div>

      <VersatilePorts />

      <div>
        <Specification />
      </div>

      {/* <FooterNew /> */}
    </div>
  );
};

export default AndposCx20Windows;
