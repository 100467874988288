import React from 'react'

function Userfriendly() {
    return (
        <div className='bg-[#F3755B]'>
            <div className='userfriendly-img container mx-auto py-[25px] xl:px-0 lg:px-0 px-[25px]'>
                <div className='container mx-auto'>
                    <div className='flex xl:flex-row lg:flex-row md:flex-row flex-col items-center'>
                        <div className='xl:w-[50%] lg:w-[50%] md:w-[50%] w-[100%]'>
                            <div className='max-w-[460px]'>
                                <p className='xl:text-[42px] lg:text-[42px] md:text-[42px] text-[30px]  font-semibold text-start text-white'>
                                    User-friendly interface
                                </p>
                                <p className='xl:text-[16px] lg:text-[16px] md:text-[14px] text-black font-normal mt-[25px] text-white'>
                                    The 4-inch screen offers a user-friendly interface,making it easy for both customers and merchants to navigatethrough transactions and settings.
                                </p>
                            </div>
                        </div>
                        <div className='max-w-[592px]'>
                        <img className='xl:w-full lg:w-full max-w-[70%] xl:mx-0 lg:mx-0 mx-auto' src='../../../../../assets/Images/friends-img.png'></img>
                        </div>
                     
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Userfriendly