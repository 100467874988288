import React from "react";
import { FaMicrochip } from "react-icons/fa6";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { BiSolidMemoryCard } from "react-icons/bi";
import { BsSoundwave } from "react-icons/bs";

const Specification = () => {
  return (
    <div className="px-8 lg:px-40 py-12">
      <div>
        <h4 className="text-3xl font-bold">Specifications</h4>
      </div>

      <div>
        <div className="flex flex-wrap w-full pt-12">
          <div className="w-full lg:w-1/4">
            <div className="m-1 bg-[#EAEDF0] min-h-44 p-3 gap-4 rounded-lg">
              <div className="flex gap-2 items-center">
                <FaMicrochip size={24} />
                <span className="font-bold">Processor</span>
              </div>
              <div>
                <span className="text-xs"> Qualcomm Quad-core, 2.0GHz</span>
              </div>
            </div>
          </div>
          <div className="w-full lg:w-1/4">
            <div className="m-1 bg-[#EAEDF0] min-h-44 p-3 gap-4 rounded-lg">
              <div className="flex gap-2 items-center">
                <AiOutlineLoading3Quarters size={24} />
                <span className="font-bold">OS</span>
              </div>
              <div>
                <span className="text-xs">Android 14</span>
              </div>
            </div>
          </div>
          <div className="w-full lg:w-1/4">
            <div className="m-1 bg-[#EAEDF0] min-h-44 p-3 gap-4 rounded-lg">
              <div className="flex gap-2 items-center">
                <BiSolidMemoryCard size={24} />
                <span className="font-bold">Memory</span>
              </div>
              <div>
                <span className="text-xs">
                  2GB+8GB
                  3GB+32GB (Optional)
                  Micro SD Card (Up to 2TB)
                </span>
                {/* <div>
                  <span className="text-xs">MicroSD Card (up to 2TB)</span>
                </div> */}
              </div>
            </div>
          </div>

          <div className="w-full lg:w-1/4">
            <div className="m-1 bg-[#EAEDF0] min-h-44 p-3 gap-4 rounded-lg">
              <div className="flex gap-2 items-center">
                <BsSoundwave size={24} />
                <span className="font-bold"> Display & Touchscreen</span>
              </div>
              <div>
                <span className="text-xs">
                6.517", 720x1600, HD
                capacitive multi-touch
                  </span>
              </div>              
            </div>
          </div>

          <div className="w-full lg:w-1/4">
            <div className="m-1 bg-[#EAEDF0] min-h-44 p-3 gap-4 rounded-lg">
              <div className="flex gap-2 items-center">
                <FaMicrochip size={24} />
                <span className="font-bold">keypad</span>
              </div>
              <div>
                <span className="text-xs">
                    1 Power key
                    2 Volume key
                </span>
              </div>
            </div>
          </div>

          <div className="w-full lg:w-1/4">
            <div className="m-1 bg-[#EAEDF0] min-h-44 p-3 gap-4 rounded-lg">
              <div className="flex gap-2 items-center">
                <FaMicrochip size={24} />
                <span className="font-bold">Audio</span>
              </div>
              <div>
                <span className="text-xs">
                  Speaker
                  Buzzer
                  Headset by USB-C
                </span>
              </div>
            </div>
          </div>

          <div className="w-full lg:w-1/4">
            <div className="m-1 bg-[#EAEDF0] min-h-44 p-3 gap-4 rounded-lg">
              <div className="flex gap-2 items-center">
                <FaMicrochip size={24} />
                <span className="font-bold"> Connectivity </span>
              </div>
              <div>
                <span className="text-xs">
                  4G, 3G, 2G
                  WiFi 2.4G, 5G
                  Bluetooth 5.0, support BLE
                </span>
              </div>
            </div>
          </div>

          <div className="w-full lg:w-1/4">
            <div className="m-1 bg-[#EAEDF0] min-h-44 p-3 gap-4 rounded-lg">
              <div className="flex gap-2 items-center">
                <FaMicrochip size={24} />
                <span className="font-bold"> Mag Card</span>
              </div>
              <div>
                <span className="text-xs">
                ISO7816, 1, 2, 3
                </span>
              </div>
            </div>
          </div>


          <div className="w-full lg:w-1/4">
            <div className="m-1 bg-[#EAEDF0] min-h-44 p-3 gap-4 rounded-lg">
              <div className="flex gap-2 items-center">
                <FaMicrochip size={24} />
                <span className="font-bold"> IC Card</span>
              </div>
              <div>
                <span className="text-xs">
                EMV L1, L2
                </span>
              </div>
            </div>
          </div>
          <div className="w-full lg:w-1/4">
            <div className="m-1 bg-[#EAEDF0] min-h-44 p-3 gap-4 rounded-lg">
              <div className="flex gap-2 items-center">
                <FaMicrochip size={24} />
                <span className="font-bold"> Contactless </span>
              </div>
              <div>
                <span className="text-xs">
                EMV L1
                </span>
              </div>
            </div>
          </div>

          <div className="w-full lg:w-1/4">
            <div className="m-1 bg-[#EAEDF0] min-h-44 p-3 gap-4 rounded-lg">
              <div className="flex gap-2 items-center">
                <FaMicrochip size={24} />
                <span className="font-bold"> SIM & SAM </span>
              </div>
              <div>
                <span className="text-xs">
                1 Nano SIM + 1 SIM + 2 SAM  
                </span>
              </div>
            </div>
          </div>

          <div className="w-full lg:w-1/4">
            <div className="m-1 bg-[#EAEDF0] min-h-44 p-3 gap-4 rounded-lg">
              <div className="flex gap-2 items-center">
                <FaMicrochip size={24} />
                <span className="font-bold"> Positionning </span>
              </div>
              <div>
                <span className="text-xs">
                GPS, GLONASS, BEIDOU
                </span>
              </div>
            </div>
          </div>

          
         

          
          <div className="w-full lg:w-1/4">
            <div className="m-1 bg-[#EAEDF0] min-h-44 p-3 gap-4 rounded-lg">
              <div className="flex gap-2 items-center">
                <FaMicrochip size={24} />
                <span className="font-bold">Camera</span>
              </div>
              <div>
                <span className="text-xs">
                Front: 0.3MP, for QR code
                Rear: 5MP, auto focus, flash
                </span>
              </div>
            </div>
          </div>
         
          <div className="w-full lg:w-1/4">
            <div className="m-1 bg-[#EAEDF0] min-h-44 p-3 gap-4 rounded-lg">
              <div className="flex gap-2 items-center">
                <FaMicrochip size={24} />
                <span className="font-bold">Battery
                </span>
              </div>
              <div>
                <span className="text-xs">
                7.2V/2600mAh
                7.2V/3350mAh (Optional)
                </span>
              </div>
            </div>
          </div>
          <div className="w-full lg:w-1/4">
            <div className="m-1 bg-[#EAEDF0] min-h-44 p-3 gap-4 rounded-lg">
              <div className="flex gap-2 items-center">
                <FaMicrochip size={24} />
                <span className="font-bold">Power Supply</span>
              </div>
              <div>
                <span className="text-xs">
                5V/2A
                </span>
              </div>
            </div>
          </div>

          <div className="w-full lg:w-1/4">
            <div className="m-1 bg-[#EAEDF0] min-h-44 p-3 gap-4 rounded-lg">
              <div className="flex gap-2 items-center">
                <FaMicrochip size={24} />
                <span className="font-bold">  Printer </span>
              </div>
              <div>
                <span className="text-xs">
                Thermal 40mm×57mm 
                </span>
              </div>
            </div>
          </div>

          <div className="w-full lg:w-1/4">
            <div className="m-1 bg-[#EAEDF0] min-h-44 p-3 gap-4 rounded-lg">
              <div className="flex gap-2 items-center">
                <FaMicrochip size={24} />
                <span className="font-bold"> Connection</span>
              </div>
              <div>
                <span className="text-xs">
                1 USB-C
 2 POGO pins
                </span>
              </div>
            </div>
          </div>

          <div className="w-full lg:w-1/4">
            <div className="m-1 bg-[#EAEDF0] min-h-44 p-3 gap-4 rounded-lg">
              <div className="flex gap-2 items-center">
                <BsSoundwave size={24} />
                <span className="font-bold">Accessories</span>
              </div>
              <div>
                <span className="text-xs">
                USB-C charging cable
 Countertop conversion kit (Optional)
 Charging base (Optional)
 Commication base (Optional
                  </span>
              </div>
              
            </div>
          </div>

          <div className="w-full lg:w-1/4">
            <div className="m-1 bg-[#EAEDF0] min-h-44 p-3 gap-4 rounded-lg">
              <div className="flex gap-2 items-center">
                <FaMicrochip size={24} />
                <span className="font-bold">Environment</span>
              </div>
              <div>
                <span className="text-xs">
                Operating temperature: -10℃
 ℃
 ℃
 to +50 ℃
 Storage temperature: -20 to +70
 Operating humidity: 
10% to 90% non-condensing
                </span>
              </div>
            </div>
          </div>
         

          <div className="w-full lg:w-1/4">
            <div className="m-1 bg-[#EAEDF0] min-h-44 p-3 gap-4 rounded-lg">
              <div className="flex gap-2 items-center">
                <FaMicrochip size={24} />
                <span className="font-bold">Certification</span>
              </div>
              <div>
                <span className="text-xs">
                PCI V6
 EMVCo Contact L1/L2
 EMVCo Contactless L1 (3.0)
 Paypass, Paywave, AMEX, JCB, 
Quics, TQM
 CE, GCF, ROHS, WEEE
                </span>
              </div>
            </div>
          </div>

        </div>

        <div className="flex justify-center mt-8">
          <a
            href="/devices-pdf/P20 brochure.pdf"
            target="_blank"
            rel="noopener noreferrer"
            className="bg-[#1398F1] py-2 px-12 text-white rounded-full text-2xl lg:text-sm font-bold"
          >
            Download brochure
          </a>
        </div>
      </div>
    </div>
  );
};

export default Specification;
