import React from 'react'
import Portability from './Portability'

function Unleash() {
    return (
        <div>
            <div className='xl:py-[100px] lg:py-[100px] md:py-[30px] py-[25px] xl:px-0 lg:px-0 px-[20px]  bg-[#FEFBFA]'>
                <div className='container mx-auto'>
                    <div className='flex xl:flex-row lg:flex-row md:flex-row flex-col items-start'>
                        <div className='xl:w-[50%] lg:w-[50%] md:w-[50%] w-[100%]'>
                            <div className='max-w-[400px]'>
                                <p className='xl:text-[42px] lg:text-[42px] md:text-[42px] text-[30px] md:text-[30px] font-semibold text-start text-[#F3755B]'>
                                    Sleek And Modern Design
                                </p>
                                <p className='xl:text-[16px] lg:text-[16px] md:text-[16px] text-black font-normal mt-[20px] leading-[29px]'>
                                    Impress customers with a sleek and
                                    modern design that not only looks
                                    professional but also reflects the
                                    cytting-edge Technology Wurgun The POS terminal.
                                </p>
                            </div>
                        </div>
                        <div className='xl:w-[50%] lg:w-[50%] md:w-[50%] w-[100%]'>
                            <div>
                                <img className=' xl:mx-0 lg:mx-0 mx-auto w-[60%] xl:mt-0 lg:mt-0 md:mt-0 mt-[30px]' src="../../../../../assets/Images/Group 90.png"></img>
                            </div>
                        </div>
                    </div>
                    <div className='xl:py-[80px] lg:py-[80px] py-[20px]'>
                        <div className='flex xl:flex-row lg:flex-row md:flex-row flex-col items-center'>
                            <div className='xl:w-[50%] lg:w-[50%] w-[50%] '>
                                <img className=' xl:mx-0 lg:mx-0 mx-auto xl:w-[60%] lg:w-[60%] w-[70%] xl:mt-0 lg:mt-0 md:mt-0 mt-[30px]' src="../../../../../assets/Images/Group 100.png"></img>
                            </div>
                            <div className='xl:w-[50%] lg:w-[50%] md:w-[50%] w-[100%]'>
                                <div className='max-w-[450px] xl:mt-0 lg:mt-0 md:mt-0 mt-[20px]'>
                                    <p className='xl:text-[42px] lg:text-[42px] md:text-[42px] text-[30px] md:text-[30px] font-semibold text-start text-[#F3755B]'>
                                        Unleash the Power.
                                    </p>
                                    <p className='xl:text-[16px] lg:text-[16px] md:text-[16px] text-black font-normal xl:mt-[25px]lg:mt-[25px] md:mt-[25px] mt-[20px] xl:mb-0 lg:mb-0 md:mb-0 mb-[15px]'>
                                        Upgrade performance and enjoy seamlesstransactions and enhanced productivity.Quad-core 2.0GHz processorMultiple memory configurations available:2+8GB, 3+32GB
                                    </p>
                                </div>
                                <div className='max-w-[450px] xl:mt-[100px] lg:mt-[100px] md:mt-[20px] text-[#F3755B]'>
                                    <p className='xl:text-[42px] lg:text-[42px] md:text-[42px] text-[30px] md:text-[30px] font-semibold text-start text-[#F3755B]'>
                                        Sleek andModern Design.
                                    </p>
                                    <p className='xl:text-[16px] lg:text-[16px] md:text-[16px] text-black font-normal xl:mt-[25px] lg:mt-[25px] md:mt-[25px] mt-[20px] leading-[29px]'>
                                        Take advantage of the improved performance and efficiency of
                                        Android 14 ensuring that your POS system runs smoothly and
                                        Efficiently even during peak business hours.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex xl:flex-row lg:flex-row md:flex-row flex-col items-start'>
                        <div className='xl:w-[50%] lg:w-[50%] md:w-[50%] w-[100%]'>
                            <div className='max-w-[400px]'>
                                <p className='xl:text-[42px] lg:text-[42px] md:text-[42px] text-[30px] md:text-[30px] font-semibold text-start text-[#F3755B]'>
                                    Fast and Reliable
                                    Printing
                                </p>
                                <p className='xl:text-[16px] lg:text-[16px] md:text-[16px] text-black font-normal mt-[25px] leading-[29px]'>
                                    Impress customers with a sleek and
                                    modern design that not only looks
                                    professional but also reflects the
                                    cytting-edge Technology Wurgun The POS terminal.
                                </p>
                            </div>
                        </div>
                        <div className='xl:w-[50%] lg:w-[50%] md:w-[50%] w-[100%]'>
                            <div className=''>
                                <img className=' xl:mx-0 lg:mx-0 mx-auto w-[60%] xl:mt-0 lg:mt-0 md:mt-0 mt-[30px]' src="../../../../../assets/Images/POS1.2_2048x 1.png"></img>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Portability />
        </div>
    )
}

export default Unleash