import React from 'react'


function Management() {
  return (
    <div className='bg-[#ffffff] xl:py-[100px] lg:py-[100px] md:py-[50px] py-[30px] xl:px-0 lg:px-0 md:px-[20px] px-[20px]'>
      <div className='container mx-auto'>
        <div className=' flex xl:flex-row lg:flex-row md:flex-row flex-col flex-col-reverse	 items-center justify-between'>
          <div className=' xl:w-[49%] lg:w-[49%] md:w-[49%] w-[100%]'>
            <p className='xl:text-[42px] lg:text-[42px] md:text-[30px] text-[30px] font-semibold text-black mb-[20px]'>
              Intuitive cable management
            </p>
            <p className='text-[16px] font-normal max-w-[560px]'>
              Incorporate intuitive cable management with in the triangular design,
              keeping cables organized and out of sight for a clean and professional look.
            </p>
            <img className='xl:mt-[90px] lg:mt-[90px] md:mt-[90px] mt-[30px] ' src="../../../../../assets/Images/manage.png"></img>
          </div>
          <div className=''>
            <img className='xl:w-full lg:w-full md:w-[50%] w-[50%]  mx-auto' src="../../../../../assets/Images/C20 Lite 2.png"></img>
          </div>

          <div>

          </div>
        </div>
      </div>
    </div>
  )
}

export default Management