import React, { useRef, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import logo from "./logo.svg";
import "./App.css";
import Contact from "./pages/Contact";
import Header from "./Component/Header/Header";
import Footer from "./Component/Footer/Footer";
import Home from "./pages/home/Home";
import { ToastContainer } from "react-toastify";
import ScrollToTop from "./common/ScrollToTop";
import Productsheet from "./pages/products/ecrproductpage/Productsheet";
import Herosection from "./pages/products/productcse/Herosection";
import ProductPage from "./pages/products/ProductPage";
import Bannersection from "./pages/products/Cxproducts/Bannersection";
import Slidersection from "./pages/products/Ltwentyproducts/Slidersection";
import Pbanner from "./pages/products/Ptwentyproducts/Pbanner";
import Unleash from "./pages/products/Pthirtyproducts/Unleash";
import Home2 from "./pages/home2/Home2";
import Demo from "./pages/demo/Demo";
import AndposCx20Windows from "./pages/AndposCx20Windows/AndposCx20Windows";
import AndposC20Pro from "./pages/AndposC20Pro/AndposC20Pro";
import AndposCx20 from "./pages/AndposCx20/AndposCx20";
import AndposC20Lite from "./pages/AndposC20Lite/AndposC20Lite";
import AndposC20Se from "./pages/AndposC20Se/AndposC20Se";
import Hs20 from "./pages/Hs20/Hs20";
import L20 from "./pages/L20/L20";
import M20Se from "./pages/M20Se/M20Se";
import Ns20Pro from "./pages/Ns20Pro/Ns20Pro";
import Ns20s from "./pages/Ns20s/Ns20s";
import Ns30 from "./pages/Ns30/Ns30";
import P20 from "./pages/P20/P20";
import P30 from "./pages/P30/P30";

function App() {
  const [loacldata, setLocaldata] = useState(false);

  const productRef = useRef();
 
  return (
    <Router>
      <ScrollToTop />
      <ToastContainer />
      <Header isOpen={setLocaldata}   />
      <Routes>
        <Route path="/" element={<Home productRef={productRef}  />} />
        <Route path="/know-more" element={<Home2 />} />
        <Route path="/know-more2" element={<Demo />} />

        <Route path="/andpos-cx20-windows" element={<AndposCx20Windows />} />
        <Route path="/andpos-c20-pro" element={<AndposC20Pro />} />
        <Route path="/andpos-cx20" element={<AndposCx20 />} />
        <Route path="/andpos-c20-lite" element={<AndposC20Lite />} />
        <Route path="/andpos-c20-se" element={<AndposC20Se />} />

        <Route path="/hs20" element={<Hs20 />} />
        <Route path="/l20" element={<L20 />} />
        <Route path="/m20-se" element={<M20Se />} />
        <Route path="/ns20-pro" element={<Ns20Pro />} />
        <Route path="/ns20-s" element={<Ns20s />} />
        <Route path="/ns30" element={<Ns30 />} />
        <Route path="/p20" element={<P20 />} />
        <Route path="/p30" element={<P30 />} />

        <Route path="/contact" element={<Contact open={loacldata} />} />
        <Route path="/Products" element={<ProductPage />} />
        <Route path="/ecr" element={<Productsheet />} />
        <Route path="/c20se" element={<Herosection />} />
        <Route path="/Cxproducts" element={<Bannersection />} />
        <Route path="/Ltwentyproducts" element={<Slidersection />} />
        <Route path="/Ptwentyproducts" element={<Pbanner />} />
        <Route path="/Pthirtyproducts" element={<Unleash />} />
      </Routes>
      <Footer productRef={productRef} />
    </Router>
  );
}

export default App;
